import { useState, useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style/form.scss";
import { Navbar, ItemForm } from "../../components";
import { NavButton } from "../../components/button/Button";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { QuestSize } from '../../components/quest/Quest';
import { GlobalContext } from "../../context";
import { ADD_CART, LIST_PRODUK, REMOVE_CART } from "../../context/types";
import { createBrowserHistory } from "history";
import { SplashScreen } from "../../components/controlFlow/ControlFlow";
import { DrawerDetil, DrawerPage } from "../../components/drawwer/DrawerDetil";
import { toast, ToastContainer } from "react-toastify";
import { ip } from "../../assets/api";
import { colors } from "../../assets/colors";
import currency from "currency.js";
import { BeatLoader } from "react-spinners";
import Alert from "../../components/alert/Alert";
import ImgEmptyStock from '../../assets/img/EmptyStock.png';
import Search from "../../components/search/Search";
import { decrypt } from "../../components/tools/encrypt";
// import { Trousers, Aks, Shirt, pantKid, shirtKid } from '../../assets/img/IconProduct';
// import CarouselComponent from "../../components/carousel/Carousel";

const FormAllClothes = () => {
  const { cart, dispatchCart } = useContext(GlobalContext);
  const [isOpenPage, setIsOpenPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [dataConst, setDataConst] = useState([]);
  const [idStuff, setIdStuff] = useState(0);
  const [idProduk, setIdProduk] = useState();
  const [price, setPrice] = useState(0);
  const [priceConst, setPriceConst] = useState(0);
  const [comboColor, setComboColor] = useState();
  const [colorChoose, setColorChoose] = useState();
  const [isForgetSelectColor, setIsForgetSelectColor] = useState(false);
  const [a1, setA1] = useState();
  const [a1Const, setA1Const] = useState();
  const [a2, setA2] = useState();
  const [a2Const, setA2Const] = useState();
  const [a3, setA3] = useState();
  const [a3Const, setA3Const] = useState();
  const [a4, setA4] = useState();
  const [a4Const, setA4Const] = useState();
  const [a5, setA5] = useState();
  const [a5Const, setA5Const] = useState();
  const [a6, setA6] = useState();
  const [a6Const, setA6Const] = useState();
  const [c7, setC7] = useState(); const [c13, setC13] = useState();
  const [c7Const, setC7Const] = useState(); const [c13Const, setC13Const] = useState();
  const [c8, setC8] = useState(); const [c14, setC14] = useState();
  const [c8Const, setC8Const] = useState(); const [c14Const, setC14Const] = useState();
  const [c9, setC9] = useState(); const [c15, setC15] = useState();
  const [c9Const, setC9Const] = useState(); const [c15Const, setC15Const] = useState();
  const [c10, setC10] = useState(); const [c16, setC16] = useState();
  const [c10Const, setC10Const] = useState(); const [c16Const, setC16Const] = useState();
  const [c11, setC11] = useState();
  const [c11Const, setC11Const] = useState();
  const [c12, setC12] = useState();
  const [c12Const, setC12Const] = useState();
  const [otherSize, setOtherSize] = useState();
  const [otherSizeConst, setOtherSizeConst] = useState();
  const [category, setCategory] = useState();
  const [buffqty, setBuffqty] = useState();
  const [discount, setDiscount] = useState(0);
  const [phone, setPhone] = useState();
  const [isOnCart, setIsOnCart] = useState(false);
  const [idCart, setIdCart] = useState();
  const [isHaveBuffer, setIsHaveBuffer] = useState(false);
  const [isAddToCart, setIsAddToCart] = useState(false);
  const [isFromSearch, setIsFromSearch] = useState(false);

  // const params = useParams();
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    CheckProductInCart()
    const getPhoneLocal = localStorage.getItem('phone');
    const _produk = location.state.produk;
    const _discount = location.state.discount;
    const _idProduk = location.state.idProduk;

    setDataConst(_produk);
    if (getPhoneLocal) {
      setPhone(getPhoneLocal);
    }
    if (_discount) {
      setDiscount(_discount);
    }
    if (_idProduk) {
      getFromCart(_idProduk);
      setIsFromSearch(true);

    } else {
      getFromCart(_produk[0].id);
      setIsFromSearch(false);
    }
  }, []);

  useEffect(() => {
    if (idProduk) {
      CheckProductInCart()
      const page = location.state.produk.findIndex(e => e.id == idProduk);
      setIdStuff(page + 1);
    }
  }, [idProduk]);

  useEffect(() => {
    if (Array.isArray(buffqty)) {
      const haveBuffer = buffqty.filter(e => parseInt(e.qty_buffer) !== 0);
      // console.log(haveBuffer);
      if (haveBuffer.length > 0) {
        const haveStock = haveBuffer.filter(e => parseInt(e.qty_avaliable) !== 0);
        if (haveStock.length > 0) {
          setIsHaveBuffer(true)
        } else {
          setIsHaveBuffer(false)
        }
      } else {
        setIsHaveBuffer(true)
      }
    } else {
      setIsHaveBuffer(true)
    }
    /* buffqty.map((value) => {
      if(value?.qty_buffer !== 0) {
        setIsHaveBuffer(true);
        setIsHaveStock(value?.qty_avaliable !== 0 ? true : false)
      } else {
        setIsHaveStock(true);
        setIsHaveBuffer(false)
      }
    }) */
  }, [buffqty]);

  useEffect(() => {
    if (data) {
      calculatePrice();
    }
  }, [a1, a2, a3, a4, a5, a6, c7, c8, c9, c10, c11, c12, c13, c14, c15, c16, otherSize])

  // useEffect(() => {
  //   if (dataConst.length > 0) {
  //     sessionStorage.setItem('list', JSON.stringify({ discount: location.state.discount, data: dataConst }))
  //   }
  // }, [dataConst])

  // useEffect(() => {
  //   if (isFromSearch) {
  //     window.onpopstate = e => {
  //       history.replace("/");
  //       window.location.reload();
  //     };
  //   }
  // }, [isFromSearch])

  /* Cek Product in Cart with all Clothes */

  function CheckProductInCart() {
    const rawData = location.state.produk;
    let newData = [];
    if (Array.isArray(rawData) && Array.isArray(cart)) {
      rawData.map(async (value) => {
        const checkOnCart = await cart.find(element => element.clothes_id == value.id)
        if (checkOnCart) {
          const data = {
            ...value,
            isOnCart: true
          }
          newData.push(data)
        } else {
          const data = {
            ...value,
            isOnCart: false
          }
          newData.push(data)
        }
      })
      setDataConst(newData);
    }
  }

  /* Pertama */
  function getFromCart(idNav) {
    // console.log("Get From Cart #Screen Form");
    // console.log(`Id Nav ${idNav}`)
    setIsLoading(true);
    setIsOpenPage(false);
    const getPhoneLocal = localStorage.getItem('phone');

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${ip}/api/pre-order/cart/${getPhoneLocal}`, requestOptions)
      .then(response => response.json())
      .then(async result => {
        // console.log("get From Cart")
        // console.log(result)
        dispatchCart({ type: ADD_CART, payload: result.cart });
        // Cek apakah barang di keranjang
        if (Array.isArray(result.cart)) {
          if (result.cart.length > 0) {
            const filterProductOnCart = await result.cart.filter(e => e.clothes_id == idNav);
            // console.log("filter Product On Cart")
            // console.log(filterProductOnCart);

            // cek kalau barang ini tidak pakai hijab
            if (filterProductOnCart.length > 0) {
              // Produk ada di keranjang
              setIsAddToCart(true);
              const productNotVeil = await filterProductOnCart.filter(e => e.veil == 0);
              // console.log("Produk Tidak Pakai Hijab");
              // console.log(productNotVeil);
              if (productNotVeil.length > 0) {
                const productColor = productNotVeil.find(e => e.info == productNotVeil[0].info);
                setColorChoose(productNotVeil[0].info);
                // console.log("Proses Pencarian final");
                if (productColor) {
                  // console.log("Final Product Founded")
                  // console.log(productColor);
                  SearchDataCart(productColor?.clothes_id);
                  setIdProduk(productColor?.clothes_id)
                  setIsOnCart(true);
                  setIdCart(productColor?.id);
                  setBuffqty(productColor?.clothes?.buffer_product)
                  // Cek Barang cateogry dewasa
                  if (productColor?.clothes?.type_id == 2) {
                    // console.log("Final Product Founded Adult")
                    setA1(productColor?.size_s); setA2(productColor?.size_m); setA3(productColor?.size_l);
                    setA1Const(productColor?.size_s); setA2Const(productColor?.size_m); setA3Const(productColor?.size_l);
                    setA4(productColor?.size_xl); setA5(productColor?.size_xxl); setA6(productColor?.size_xxxl);
                    setA4Const(productColor?.size_xl); setA5Const(productColor?.size_xxl); setA6Const(productColor?.size_xxxl);
                    setPriceConst(productColor?.total);
                    setColorChoose(productColor?.info);
                    setIsLoading(false);
                  } else if (productColor?.clothes?.type_id == 4) {
                    setA1(productColor?.size_27); setA2(productColor?.size_28); setA3(productColor?.size_29);
                    setA1Const(productColor?.size_27); setA2Const(productColor?.size_28); setA3Const(productColor?.size_29);
                    setA4(productColor?.size_30); setA5(productColor?.size_31); setA6(productColor?.size_32);
                    setA4Const(productColor?.size_30); setA5Const(productColor?.size_31); setA6Const(productColor?.size_32);
                    setC7(productColor?.size_33); setC8(productColor?.size_34); setC9(productColor?.size_35);
                    setC7Const(productColor?.size_33); setC8Const(productColor?.size_34); setC9Const(productColor?.size_35);
                    setC10(productColor?.size_36); setC11(productColor?.size_37); setC12(productColor?.size_38);
                    setC10Const(productColor?.size_36); setC11Const(productColor?.size_37); setC12Const(productColor?.size_38);
                    setC13(productColor?.size_39); setC14(productColor?.size_40); setC15(productColor?.size_41);
                    setC13Const(productColor?.size_39); setC14Const(productColor?.size_40); setC15Const(productColor?.size_41);
                    setC16(productColor?.size_42)
                    setC16Const(productColor?.size_42)
                    setPriceConst(productColor?.total);
                    setColorChoose(productColor?.info);
                    setIsLoading(false);
                  } else if (productColor?.clothes?.type_id == 5) {
                    setOtherSize(productColor?.other)
                    setOtherSizeConst(productColor?.other)
                    setPriceConst(productColor?.total);
                    setColorChoose(productColor?.info);
                    setIsLoading(false);
                  } else {
                    // console.log("Final Product Founded Kid")
                    setA1(productColor?.size_2); setA2(productColor?.size_4); setA3(productColor?.size_6);
                    setA1Const(productColor?.size_2); setA2Const(productColor?.size_4); setA3Const(productColor?.size_6);
                    setA4(productColor?.size_8); setA5(productColor?.size_10); setA6(productColor?.size_12);
                    setA4Const(productColor?.size_8); setA5Const(productColor?.size_10); setA6Const(productColor?.size_12);
                    setPriceConst(productColor?.total);
                    setColorChoose(productColor?.info);
                    setIsLoading(false);
                  }
                } else {
                  getDataAPI(idNav)
                }
              } else {
                getDataAPI(idNav);
              }
            } else {
              getDataAPI(idNav)
              setIsAddToCart(false);
            }
          } else {
            getDataAPI(idNav)
          }
        }
      })
      .catch(error => {
        console.error(error);
        toast.error(error.message, {
          autoClose: 1500, onClose: () => navigate(-1
          )
        })
      });
  }

  function getDataByColor({ color }) {
    if (isOnCart) {
      setA1(); setA2(); setA3(); setA4(); setA5(); setA6(); setC7(); setC8(); setC9(); setC10(); setC11(); setC12(); setC13(); setC14(); setC15(); setC16(); setOtherSize();
    }
    setIsLoading(true);
    setColorChoose(color);

    const getPhoneLocal = localStorage.getItem('phone');
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${ip}/api/pre-order/cart/${getPhoneLocal}`, requestOptions)
      .then(response => response.json())
      .then(async result => {
        // console.log(`get From Cart by ${color}`);
        // console.log(`idProduk ${idProduk}`)
        dispatchCart({ type: ADD_CART, payload: result.cart });
        // Cek apakah barang di keranjang
        if (result.cart.length > 0) {
          const filterProductOnCart = await result.cart.filter(e => e.clothes_id == data?.id);
          // console.log("filter Product On Cart")
          // console.log(filterProductOnCart);

          // cek kalau barang ini tidak pakai hijab
          if (filterProductOnCart) {
            const productNotVeil = await filterProductOnCart.filter(e => e.veil == 0);
            // console.log("Produk Tidak Pakai Hijab");

            if (productNotVeil.length > 0) {
              // console.log("Proses Pencarian final");
              const productColor = await productNotVeil.find(e => e.info == color);
              // console.log(productColor);

              if (productColor) {
                // console.log("Final Product Found")
                setIdProduk(productColor?.clothes_id)
                setIsOnCart(true);
                setIdCart(productColor?.id);
                setBuffqty(productColor?.clothes.buffer_product)
                SearchDataCart(productColor?.clothes_id);

                // Cek Barang cateogry dewasa
                if (productColor?.clothes?.type_id == 2) {
                  // console.log("Final Product Founded Adult")
                  setA1(productColor?.size_s); setA2(productColor?.size_m); setA3(productColor?.size_l);
                  setA1Const(productColor?.size_s); setA2Const(productColor?.size_m); setA3Const(productColor?.size_l);
                  setA4(productColor?.size_xl); setA5(productColor?.size_xxl); setA6(productColor?.size_xxxl);
                  setA4Const(productColor?.size_xl); setA5Const(productColor?.size_xxl); setA6Const(productColor?.size_xxxl);
                  setPriceConst(productColor?.total);
                  setColorChoose(productColor?.info);
                  setIsLoading(false);
                } else if (productColor?.clothes?.type_id == 4) {
                  setA1(productColor?.size_27); setA2(productColor?.size_28); setA3(productColor?.size_29);
                  setA1Const(productColor?.size_27); setA2Const(productColor?.size_28); setA3Const(productColor?.size_29);
                  setA4(productColor?.size_30); setA5(productColor?.size_31); setA6(productColor?.size_32);
                  setA4Const(productColor?.size_30); setA5Const(productColor?.size_31); setA6Const(productColor?.size_32);
                  setC7(productColor?.size_33); setC8(productColor?.size_34); setC9(productColor?.size_35);
                  setC7Const(productColor?.size_33); setC8Const(productColor?.size_34); setC9Const(productColor?.size_35);
                  setC10(productColor?.size_36); setC11(productColor?.size_37); setC12(productColor?.size_38);
                  setC10Const(productColor?.size_36); setC11Const(productColor?.size_37); setC12Const(productColor?.size_38);
                  setC13(productColor?.size_39); setC14(productColor?.size_40); setC15(productColor?.size_41);
                  setC13Const(productColor?.size_39); setC14Const(productColor?.size_40); setC15Const(productColor?.size_41);
                  setC16(productColor?.size_42)
                  setC16Const(productColor?.size_42)
                  setPriceConst(productColor?.total);
                  setColorChoose(productColor?.info);
                  setIsLoading(false);
                } else if (productColor?.clothes?.type_id == 5) {
                  setOtherSize(productColor?.other)
                  setOtherSizeConst(productColor?.other)
                  setPriceConst(productColor?.total);
                  setColorChoose(productColor?.info);
                  setIsLoading(false);
                } else {
                  // console.log("Final Product Founded Kid")
                  setA1(productColor?.size_2); setA2(productColor?.size_4); setA3(productColor?.size_6);
                  setA1Const(productColor?.size_2); setA2Const(productColor?.size_4); setA3Const(productColor?.size_6);
                  setA4(productColor?.size_8); setA5(productColor?.size_10); setA6(productColor?.size_12);
                  setA4Const(productColor?.size_8); setA5Const(productColor?.size_10); setA6Const(productColor?.size_12);
                  setPriceConst(productColor?.total);
                  setColorChoose(productColor?.info);
                  setIsLoading(false);
                }
              } else {
                getDataAPIColor(color);
              }
            } else {
              getDataAPIColor(color);
            }
          } else {
            getDataAPIColor(color)
          }
        } else {
          getDataAPIColor(color)
        }
      })
      .catch(error => {
        console.error(error);
        toast.error(error.message, { autoClose: 1500 });
      });
  }

  function getDataAPI(newIdNav) {
    setIsOnCart(false);
    // const id = location.state.idProduk;
    const phone = localStorage.getItem('phone');
    const requestOptions = {
      method: "GET",
      redirect: 'follow',
    };
    fetch(`${ip}/api/pre-order/${phone}/clothes/${newIdNav ? newIdNav : idProduk}`, requestOptions)
      .then(async res => {
        // console.log("GET DATA API By Color")
        const response = await res.json()
        const resData = response.data;
        // console.log(response)

        if (res.status == 200) {
          setIdProduk(resData.id);
          setIsLoading(false)
          setCategory(resData.type_id);
          setData(resData);
          setBuffqty(resData.buffer_product);
          modComboColor(resData.combo)
        } else {
          toast.error(response.message, { autoClose: 900, onClose: () => navigate(-1) })
        }
      })
      .catch(err => {
        console.error(err)
        toast(err.message, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          type: 'error',
        })
      })
  }

  function getDataAPIColor(color) {
    setIsOnCart(false);
    setColorChoose(color);
    // setA1(); setA2(); setA3(); setA4(); setA5(); setA6();
    const phone = localStorage.getItem('phone');
    const requestOptions = {
      method: "GET",
      redirect: 'follow',
    };
    fetch(`${ip}/api/pre-order/${phone}/clothes/${idProduk}`, requestOptions)
      .then(async res => {
        // console.log("GET DATA API")
        // console.log(res);
        const response = await res.json()
        const resData = response.data;

        if (res.status == 200) {
          // setIdProduk(resData.id);
          // console.log(resData);
          setIsLoading(false)
          setData(resData);
          setBuffqty(resData.buffer_product);
          // setCategory(resData.type_id);
          modComboColor(resData.combo)
        } else {
          toast.error(response.message, { autoClose: 900, onClose: () => navigate(-1) })
        }
      })
      .catch(err => {
        console.error(err)
        toast(err.message, {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          type: 'error',
        })
      })
  }

  function searchData(idProduct) {
    const getStateConst = dataConst.find(e => e.id == idProduct);
    // console.log("getStateConst")
    // console.log(getStateConst)
    getFromCart(getStateConst.id)
  }

  async function SearchDataCart(clothes_id) {
    // console.log(`Clothes Id ${clothes_id}`)
    const state = sessionStorage.getItem('list');
    const parseState = await decrypt(state, LIST_PRODUK);
    const searchProductId = parseState.data.find(e => e.id == clothes_id)
    if (searchProductId) {
      // console.log("Search Data Session");
      // console.log(searchProductId.type_id);
      // console.log(searchProductId);
      setData(searchProductId);
      setCategory(searchProductId.type_id);
      modComboColor(searchProductId.combo);
      setIsLoading(false)
    } /* else {
      toast.error("Terjadi Error", {autoClose: 1000, onClose: () => navigate(-1)})
    } */
  }

  function validationSend({ isNav, isNext, isEditRoute }) {
    setIsLoading(true);
    if (isOnCart) {
      if (price != priceConst) {
        if (price == 0) {
          alertConfirmDelete({ isNav, isNext })
        } else {
          sendUpdateApi({ isNav, isNext, isDelete: false });
        }
      } else if (isEditRoute) {
        sendUpdateApi({ isNav, isNext, isDelete: false });
      } else {
        isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2])
      }
    } else {
      if (a1 || a2 || a3 || a4 || a5 || a6 || c7 || c8 || c9 || c10 || c11 || c12 || c13 || c14 || c15 || c16 || otherSize) {
        if (isOnCart) {
          isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]);
        } else {
          if (comboColor) {
            if (colorChoose) {
              sendToCart({ isNav, isNext });
            } else {
              setIsForgetSelectColor(true);
              toast.warn("Pilih Warna terlebih dahulu", { autoClose: 1500, onClose: () => setIsLoading(false) })
            }
          } else if (price != 0 || price) {
            sendToCart({ isNav, isNext });
          } else {
            isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]);
          }
        }
      } else if (price == 0 || price) {
        isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]);
      }
      // else {
      //   alertConfirmNext()
      // }
    }
  }

  function sendToCart({ isNav, isNext }) {
    /* console.log(`${ip}/api/pre-order/${phone}`)
    console.log(`
      Send to Data
      idProduk : ${data.id},
      veil : 0,
      color: ${colorChoose},
      size_s", ${category == 2 && a1 ? a1 : 0},
      size_m", ${category == 2 && a2 ? a2 : 0},
      size_l", ${category == 2 && a3 ? a3 : 0},
      size_xl", ${category == 2 && a4 ? a4 : 0},
      size_xxl", ${category == 2 && a5 ? a5 : 0},
      size_xxxl", ${category == 2 && a6 ? a6 : 0},
      "size_2", ${category == 1 && a1 ? a1 : 0},
      "size_4", ${category == 1 && a2 ? a2 : 0},
      "size_6", ${category == 1 && a3 ? a3 : 0},
      "size_8", ${category == 1 && a4 ? a4 : 0},
      "size_10", ${category == 1 && a5 ? a5 : 0},
      "size_12", ${category == 1 && a6 ? a6 : 0},
      "other", ${category == 5 && otherSize ? otherSize : 0},
      category: ${category},
    `) */
    let formdata = new FormData();
    formdata.append("clothes_id", data.id);
    formdata.append("info", colorChoose ? colorChoose : "-");
    formdata.append("veil", 0);

    // Baju
    formdata.append("size_s", category == 2 && a1 ? a1 : 0); formdata.append("size_2", (category == 1 || category == 3) && a1 ? a1 : 0);
    formdata.append("size_m", category == 2 && a2 ? a2 : 0); formdata.append("size_4", (category == 1 || category == 3) && a2 ? a2 : 0);
    formdata.append("size_l", category == 2 && a3 ? a3 : 0); formdata.append("size_6", (category == 1 || category == 3) && a3 ? a3 : 0);
    formdata.append("size_xl", category == 2 && a4 ? a4 : 0); formdata.append("size_8", (category == 1 || category == 3) && a4 ? a4 : 0);
    formdata.append("size_xxl", category == 2 && a5 ? a5 : 0); formdata.append("size_10", (category == 1 || category == 3) && a5 ? a5 : 0);
    formdata.append("size_xxxl", category == 2 && a6 ? a6 : 0); formdata.append("size_12", (category == 1 || category == 3) && a6 ? a6 : 0);

    // Celena
    formdata.append("size_27", category == 4 && a1 ? a1 : 0);
    formdata.append("size_28", category == 4 && a2 ? a2 : 0);
    formdata.append("size_29", category == 4 && a3 ? a3 : 0);
    formdata.append("size_30", category == 4 && a4 ? a4 : 0);
    formdata.append("size_31", category == 4 && a5 ? a5 : 0);
    formdata.append("size_32", category == 4 && a6 ? a6 : 0);
    formdata.append("size_33", category == 4 && c7 ? c7 : 0);
    formdata.append("size_34", category == 4 && c8 ? c8 : 0);
    formdata.append("size_35", category == 4 && c9 ? c9 : 0);
    formdata.append("size_36", category == 4 && c10 ? c10 : 0);
    formdata.append("size_37", category == 4 && c11 ? c11 : 0);
    formdata.append("size_38", category == 4 && c12 ? c12 : 0);
    formdata.append("size_39", category == 4 && c13 ? c13 : 0);
    formdata.append("size_40", category == 4 && c14 ? c14 : 0);
    formdata.append("size_41", category == 4 && c15 ? c15 : 0);
    formdata.append("size_42", category == 4 && c16 ? c16 : 0);

    // Aksesoris
    formdata.append("other", category == 5 && otherSize ? otherSize : 0);
    formdata.append("total", price);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(`${ip}/api/pre-order/${phone}`, requestOptions)
      // fetch(`http://192.168.0.129:8000/api/pre-order/08987654321`, requestOptions)
      .then(async response => {
        // console.log(response)
        // console.log("Post Ke Keranjang");
        const result = await response.json();
        // console.log(result);
        if (response.status == 200) {
          toast.success(result.message, {
            autoClose: 900, onClose: () => {
              { !isNav ? getFromCart(data?.id) : isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]) }
              setIsLoading(false)
            }
          });
        } else {
          console.log(error)
          toast.error(result.message, { autoClose: 2000, onClose: () => setIsLoading(false) });
        }
      })
      .catch(error => {
        console.error(error.message);
        toast.error(error.message, { autoClose: 2000, onClose: () => setIsLoading(false) })
      });
  }

  function sendUpdateApi({ isNav, isNext, isDelete }) {
    setIsLoading(true)
    const getPhoneLocal = localStorage.getItem('phone');
    /* console.log(`
      Update to Data
      idProduk : ${data.id},
      idCart: ${params.idProduk},
      veil : 0,
      color: ${colorChoose},
      size_s", ${category == 1 && a1 ? a1 : 0},
      size_m", ${category == 1 && a2 ? a2 : 0},
      size_l", ${category == 1 && a3 ? a3 : 0},
      size_xl", ${category == 1 && a4 ? a4 : 0},
      size_xxl", ${category == 1 && a5 ? a5 : 0},
      size_xxxl", ${category == 1 && a6 ? a6 : 0},
      "size_2", ${category == 2 && a1 ? a1 : 0},
      "size_4", ${category == 2 && a2 ? a2 : 0},
      "size_6", ${category == 2 && a3 ? a3 : 0},
      "size_8", ${category == 2 && a4 ? a4 : 0},
      "size_10", ${category == 2 && a5 ? a5 : 0},
      "size_12", ${category == 2 && a6 ? a6 : 0},
      "other", ${category == 5 && otherSize ? otherSize : 0},
      data: ${data},
      buffer_produk: ${buffqty},
      category: ${category},
    `) */

    let formdata = new FormData();
    formdata.append('_method', "put")
    formdata.append("clothes_id", data.id);
    formdata.append("info", colorChoose ? colorChoose : "-");
    formdata.append("veil", 0);

    // Baju
    formdata.append("size_s", category == 2 && a1 ? a1 : 0); formdata.append("size_2", (category == 1 || category == 3) && a1 ? a1 : 0);
    formdata.append("size_m", category == 2 && a2 ? a2 : 0); formdata.append("size_4", (category == 1 || category == 3) && a2 ? a2 : 0);
    formdata.append("size_l", category == 2 && a3 ? a3 : 0); formdata.append("size_6", (category == 1 || category == 3) && a3 ? a3 : 0);
    formdata.append("size_xl", category == 2 && a4 ? a4 : 0); formdata.append("size_8", (category == 1 || category == 3) && a4 ? a4 : 0);
    formdata.append("size_xxl", category == 2 && a5 ? a5 : 0); formdata.append("size_10", (category == 1 || category == 3) && a5 ? a5 : 0);
    formdata.append("size_xxxl", category == 2 && a6 ? a6 : 0); formdata.append("size_12", (category == 1 || category == 3) && a6 ? a6 : 0);

    // Celena
    formdata.append("size_27", category == 4 && a1 ? a1 : 0);
    formdata.append("size_28", category == 4 && a2 ? a2 : 0);
    formdata.append("size_29", category == 4 && a3 ? a3 : 0);
    formdata.append("size_30", category == 4 && a4 ? a4 : 0);
    formdata.append("size_31", category == 4 && a5 ? a5 : 0);
    formdata.append("size_32", category == 4 && a6 ? a6 : 0);
    formdata.append("size_33", category == 4 && c7 ? c7 : 0);
    formdata.append("size_34", category == 4 && c8 ? c8 : 0);
    formdata.append("size_35", category == 4 && c9 ? c9 : 0);
    formdata.append("size_36", category == 4 && c10 ? c10 : 0);
    formdata.append("size_37", category == 4 && c11 ? c11 : 0);
    formdata.append("size_38", category == 4 && c12 ? c12 : 0);
    formdata.append("size_39", category == 4 && c13 ? c13 : 0);
    formdata.append("size_40", category == 4 && c14 ? c14 : 0);
    formdata.append("size_41", category == 4 && c15 ? c15 : 0);
    formdata.append("size_42", category == 4 && c16 ? c16 : 0);

    // Aksesoris
    formdata.append("other", category == 5 && otherSize ? otherSize : 0);
    formdata.append("total", price);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      mode: 'cors',
    };

    fetch(`${ip}/api/pre-order/${getPhoneLocal}/cart/${idCart}`, requestOptions)
      .then(async response => response.json())
      .then(result => {
        // console.log(result)
        if (result.status == "success") {
          /* if(isDelete) {
            deleteStuff({id: idCart, isNav, isNext})
          } else {
          } */
          getFromCart(data.id);
          toast.success(result.message, {
            autoClose: 900, onClose: () => {
              { !isNav ? getFromCart(data.id) : isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]) }
              setIsLoading(false)
            }
          })
        } else {
          toast.error(result.message, { autoClose: 1300, onClose: () => setIsLoading(false) })
          console.log(result);
        }
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, { autoClose: 2000, onClose: () => setIsLoading(false) })
        setIsLoading(false)
      });
  }

  function deleteStuff({ id, isNav, isNext }) {
    const phone = localStorage.getItem('phone');
    let formdata = new FormData();
    formdata.append("_method", "delete")

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      mode: 'cors'
    };

    fetch(`${ip}/api/pre-order/${phone}/clothes/${id}`, requestOptions)
      .then(res => res.json())
      .then(response => {
        if (response.status == "success") {
          setPriceConst(0);
          getFromCart(data.id);
          dispatchCart({ type: REMOVE_CART, payload: id })
          toast.success(response.message, {
            autoClose: 900, onClose: () => {
              { !isNav ? getFromCart(data.id) : isNext ? next(dataConst[idStuff]) : previous(dataConst[idStuff - 2]) }
              setIsLoading(false)
            }
          })
        } else {
          toast.error(response.message, { autoClose: 1500 })
        }
      })
      .catch(error => { console.error(error); toast.error(error.message, { autoClose: 900 }) })
  }

  function handleDrawer(e) {
    setIsOpenPage(true);
    e.preventDefault();
  }

  /* Tools */
  function calculatePrice() {
    function calcDisc(data) {
      const discountCase = currency(data).multiply(discount / 100);
      const finalPrice = currency(data).subtract(Math.round(discountCase));
      return finalPrice;
    }

    const dataPrice = category == 2 ? [
      a1 ? a1 * calcDisc(data.size_s) : 0,
      a2 ? a2 * calcDisc(data.size_m) : 0,
      a3 ? a3 * calcDisc(data.size_l) : 0,
      a4 ? a4 * calcDisc(data.size_xl) : 0,
      a5 ? a5 * calcDisc(data.size_xxl) : 0,
      a6 ? a6 * calcDisc(data.size_xxxl) : 0
    ] : category == 4 ? [
      !a1 ? 0 : a1 * calcDisc(data.size_27),
      !a2 ? 0 : a2 * calcDisc(data.size_28),
      !a3 ? 0 : a3 * calcDisc(data.size_29),
      !a4 ? 0 : a4 * calcDisc(data.size_30),
      !a5 ? 0 : a5 * calcDisc(data.size_31),
      !a6 ? 0 : a6 * calcDisc(data.size_32),
      !c7 ? 0 : c7 * calcDisc(data.size_33),
      !c8 ? 0 : c8 * calcDisc(data.size_34),
      !c9 ? 0 : c9 * calcDisc(data.size_35),
      !c10 ? 0 : c10 * calcDisc(data.size_36),
      !c11 ? 0 : c11 * calcDisc(data.size_37),
      !c12 ? 0 : c12 * calcDisc(data.size_38),
      !c13 ? 0 : c13 * calcDisc(data.size_39),
      !c14 ? 0 : c14 * calcDisc(data.size_40),
      !c15 ? 0 : c15 * calcDisc(data.size_41),
      !c16 ? 0 : c16 * calcDisc(data.size_42),
    ] : category == 5 ? [
      otherSize ? otherSize * calcDisc(data.other) : 0
    ] : [
      !a1 ? 0 : a1 * calcDisc( data.size_2[0]),
      !a2 ? 0 : a2 * calcDisc( data.size_4[0]),
      !a3 ? 0 : a3 * calcDisc( data.size_6[0]),
      !a4 ? 0 : a4 * calcDisc( data.size_8[0]),
      !a5 ? 0 : a5 * calcDisc( data.size_10[0]),
      !a6 ? 0 : a6 * calcDisc( data.size_12[0])
    ]

    let totalPrice = 0;
    
    for (let i = 0; i < dataPrice.length; i++) {
      const casePrice = currency(totalPrice).add(dataPrice[i]).value;
      totalPrice = casePrice;
    }
    
    if(totalPrice !== NaN) {  
      setPrice(totalPrice)
    }
  }

  function modComboColor(data) {
    let colors = [];

    if (Array.isArray(data)) {
      data.map(value => {
        colors.push({ label: value, value: value.trim() })
      })
      setComboColor(colors);
    } else {
      setComboColor(undefined)
    }
  }

  function limitInput(idBuffer, input, constant) {
    const intBuff = parseInt(buffqty[idBuffer].qty_buffer);
    const intAvail = parseInt(buffqty[idBuffer].qty_avaliable);
    const intConst = parseInt(constant);

    /* if(isOnCart) {
      if(intBuff != 0) {
        if (intAvail != 0) {
          if (input <= intAvail) {
            return input
          } else {
            return intAvail + intConst
          }
        } else {
          return input
        }
      } else {
        return input
      }
    } else {
      if(intBuff != 0) {
        if (intAvail != 0) {
          if (input <= intAvail) {
            return input
          } else {
            return intAvail
          }
        } else {
          return input
        }
      } else {
        return input
      }
    } */
    if (intBuff != 0 && intAvail != 0) {
      // Schema Buffer
      if (input <= intAvail) {
        return input
      } else {
        return isOnCart ? intAvail + intConst : intAvail
      }
    } else {
      // Schema PO
      if (intBuff == 0) {
        return input
      } else {
        return 0;
      }
    }
  }

  const LoopingPage = () => {
    return (
      Array.isArray(dataConst) && dataConst.length > 0 ? dataConst.map((value, key) => {
        return <ItemForm
          entity={data.entity_name}
          brand={value.article_name}
          idPage={key + 1}
          code={value.keyword}
          key={key}
          isAllProduct
          isOnCart={value.isOnCart}
          onCLick={(e) => {
            e.preventDefault();
            setA1(); setA2(); setA3(); setA4(); setA5(); setA6(); setColorChoose(undefined);
            getFromCart(value.id);
            setIsOpenPage(false);
          }}
        />
      }) : null)
  }

  // Navigasi

  function previous(data) {
    // console.log('previous')
    // console.log(data)
    setIsOnCart(false);
    setA1(); setA2(); setA3(); setA4(); setA5(); setA6(); setC7(); setC8(); setC9(); setC10(); setC11(); setC12(); setC13(); setC14(); setC15(); setC16(); setOtherSize(); setColorChoose(undefined);
    setPrice(0);
    setPriceConst(0);
    setData(null);
    setIdCart(null);
    setBuffqty(null);
    setCategory(null);
    searchData(data?.id);
    setIsForgetSelectColor(false);
  }

  function next(data) {
    if (idStuff == location?.state?.produk.length) {
      navigate('/cart', { replace: true })
    } else {
      setIsOnCart(false);
      setA1(); setA2(); setA3(); setA4(); setA5(); setA6(); setC7(); setC8(); setC9(); setC10(); setC11(); setC12(); setC13(); setC14(); setC15(); setC16(); setOtherSize(); setColorChoose(undefined);
      setPrice(0);
      setPriceConst(0);
      setData(null);
      setIdCart(null);
      setBuffqty(null);
      setCategory(null);
      searchData(data?.id);
      setIsForgetSelectColor(false);
    }
  }

  function alertConfirmDelete({ isNav, isNext }) {
    confirmAlert({
      customUI: ({ onClose, }) => {
        return <Alert
          titleAlert={"Peringatan"}
          isAlert
          contentAlert={`Anda belum melakukan input pada produk ini!`}
          questAlert={`Apakah anda ingin menghapus produk ini dari keranjang?`}
          txtNo={"tidak"}
          txtYes={"ya"}
          pressNo={() => {
            onClose()
            setIsLoading(false)
          }}
          pressYes={() => {
            // sendUpdateApi({ isNav, isNext, isDelete: true });
            deleteStuff({ id: idCart, isNav, isNext });
            onClose();
          }}
        />
      }
    })
  }

  function backToHome() {
    history.replace("/");
    window.location.reload();
  }

  const ButtonReq = ({ title, onClick, isEdit, isDelete }) => {
    return (
      <button className={`rounded text-white text-2xl font-bold w-full py-2 cursor-pointer mt-5 ${isEdit ? "bg-editbtn active:bg-editBtnActive" : isDelete ? "bg-denied active:bg-deniedActive" : "bg-main active:bg-btnDisable"}`} type="submit" onClick={onClick} disabled={isLoading} >
        {isLoading ? <BeatLoader color="white" /> : title}
      </button>
    )
  }

  return data ?
    (
      <div className="w-screen flex justify-center font-sans">
        <div className="w-full lg:w-1/3">
          <div className="h-11 w-full">
            <Navbar isDashboard={false} pressBack={() => isFromSearch ? backToHome() : navigate(-1, { replace: true })} customNavCart={() => {navigate('/cart'); setIsFromSearch(false)}} />
          </div>
          <div className="w-full flex justify-center mt-5">
            <Search placeholder={"Klik untuk cari barang"} onStartInput={() => navigate("/search", { state: { data: location.state.produk } })} />
          </div>
          <ToastContainer />
          <DrawerPage isOpenPage={isOpenPage} isAllProduct pressCloseDrawer={() => setIsOpenPage(false)} children={<LoopingPage />} />
          <div className="px-3 flex flex-col box-border w-full mb-8">
            {/* <div className="carousel">
            <CarouselComponent imgUrl={data.type_id == 1 ? shirtKid : data.type_id == 2 ? Shirt :data.type_id == 3 ? pantKid : data.type_id == 4 ? Trousers : Aks} />
          </div> */}
            <div className="flex flex-col justify-center box-border mt-5">
              <DrawerDetil data={data} isAllProduct discount={discount} brand={data.article_name} />
            </div>
            {isHaveBuffer || isAddToCart ? (
              <div className="flex flex-col items-center justify-center">
                <QuestSize
                  discount={discount}
                  category={data.type_id}
                  onChange1={e => setA1(limitInput(0, e.target.value, a1Const))} value1={a1}
                  onChange2={e => setA2(limitInput(1, e.target.value, a2Const))} value2={a2}
                  onChange3={e => setA3(limitInput(2, e.target.value, a3Const))} value3={a3}
                  onChange4={e => setA4(limitInput(3, e.target.value, a4Const))} value4={a4}
                  onChange5={e => setA5(limitInput(4, e.target.value, a5Const))} value5={a5}
                  onChange6={e => setA6(limitInput(5, e.target.value, a6Const))} value6={a6}
                  onChange7={e => setC7(limitInput(6, e.target.value, c7Const))} value7={c7}
                  onChange8={e => setC8(limitInput(7, e.target.value, c8Const))} value8={c8}
                  onChange9={e => setC9(limitInput(8, e.target.value, c9Const))} value9={c9}
                  onChange10={e => setC10(limitInput(9, e.target.value, c10Const))} value10={c10}
                  onChange11={e => setC11(limitInput(10, e.target.value, c11Const))} value11={c11}
                  onChange12={e => setC12(limitInput(11, e.target.value, c12Const))} value12={c12}
                  onChange13={e => setC13(limitInput(12, e.target.value, c13Const))} value13={c13}
                  onChange14={e => setC14(limitInput(13, e.target.value, c14Const))} value14={c14}
                  onChange15={e => setC15(limitInput(14, e.target.value, c15Const))} value15={c15}
                  onChange16={e => setC16(limitInput(15, e.target.value, c16Const))} value16={c16}
                  onChangeOther={e => setOtherSize(limitInput(0, e.target.value, otherSizeConst))} valueOther={otherSize}
                  optionsColor={Array.isArray(comboColor) ? comboColor : null}
                  onChangeColor={e => { getDataByColor({ color: e.value }) }}
                  valueColor={colorChoose}
                  isForgetSelectColor={isForgetSelectColor}
                  stock={buffqty}
                  price={price}
                  price1={category == 2 ? data.size_s : category == 4 ? data.size_27 : data.size_2[0]} price2={category == 2 ? data.size_m : category == 4 ? data.size_28 : data.size_4[0]}
                  price3={category == 2 ? data.size_l : category == 4 ? data.size_29 : data.size_6[0]} price4={category == 2 ? data.size_xl : category == 4 ? data.size_30 : data.size_8[0]}
                  price5={category == 2 ? data.size_xxl : category == 4 ? data.size_31 : data.size_10[0]} price6={category == 2 ? data.size_xxxl : category == 4 ? data.size_32 : data.size_12[0]}
                  price7={category == 4 ? data.size_33 : 0} price8={category == 4 ? data.size_34 : null} price9={category == 4 ? data.size_35 : null} price10={category == 4 ? data.size_36 : null}
                  price11={category == 4 ? data.size_37 : null} price12={category == 4 ? data.size_38 : null} price13={category == 4 ? data.size_39 : null} price14={category == 4 ? data.size_40 : null}
                  price15={category == 4 ? data.size_41 : null} price16={category == 4 ? data.size_42 : null}
                  priceOther={data.other}
                  entity={"MUTIF"}
                  isVeil={data.size_2.length > 1 ? true : false}
                  pressToVeil={() => navigate(`/katalog/produk/veil/${idProduk}`)}
                  isReadOnly={isLoading}
                />
                {isOnCart && (price != 0 || price) ? (
                  <ButtonReq isEdit title={"Edit"} onClick={(e) => { e.preventDefault(); validationSend({ isNav: false, isEditRoute: true }) }} />
                ) : price != 0 || price ? (
                  <ButtonReq title={"Tambah"} onClick={(e) => { e.preventDefault(); validationSend({ isNav: false }) }} />
                ) : isOnCart ? (
                  <ButtonReq isDelete title={"Hapus"} onClick={(e) => { e.preventDefault(); validationSend({ isNav: false }) }} />
                ) : null}
                {/* {isOnCart ? (
                
              ) : null
            ) : isOnCart &&  (price != 0 || price) ? (
              
            ) : (
              <button className="add-button" type="submit" onClick={() => validationSend({isNav: false})} style={{ backgroundColor: colors.edit }} disabled={isLoading} >
                {isLoading ? <BeatLoader color="white" /> : 'Edit'}
              </button>
            ) : null} */}
              </div>
            ) : (
              <div className="w-full flex flex-col items-center text-center my-2">
                <img className="w-10/12" src={ImgEmptyStock} alt="Gambar Produk Kosong" />
                <p className="text-3xl text-center font-bold text-main">Stok Kosong</p>
              </div>
            )}

          </div>

          <div className="w-full flex justify-evenly items-center pb-5">
            <div className="w-1/4" style={{ visibility: idStuff == 1 ? 'hidden' : 'visible' }}>
              <NavButton title={"previous"} isPrevious onClick={e => validationSend({ isNav: true, isNext: false })} isDisabled={isLoading} />
            </div>
            <div className="w-1/3 text-center" onClick={handleDrawer}>{idStuff} of {Array.isArray(location?.state?.produk) ? location?.state?.produk.length : dataConst.length}</div>
            <div className="w-1/4">
              <NavButton title={idStuff == location?.state?.produk.length ? "keranjang" : "next"} onClick={e => validationSend({ isNav: true, isNext: true })} isDisabled={isLoading} />
            </div>
          </div>
        </div>

      </div>
    ) : <SplashScreen />;

}

export default FormAllClothes;
