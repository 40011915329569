import { BiSearchAlt as IconSearch } from 'react-icons/bi';

const Search = ({placeholder, onChange, onStartInput, style}) => {
    return(
        <div className="w-5/6 flex justify-center items-center">
            <div className="flex justify-between items-center border border-solid rounded-md w-full box-border py-2 px-2 lg:h-full" style={style}>
                <input type="text" className="flex border-none p-1 h-full w-5/6 active:border-none" onChange={onChange} onClick={onStartInput} placeholder={placeholder} />
                <IconSearch size={30} className='w-1/6' />
            </div>
        </div>
    )
}

export default Search;