import currency from "currency.js";
import { colors } from "../../assets/colors";

const BoxItem = ({ nameProduct, lowPrice, highPrice, srcImg, pressToProduct, entityName }) => {
    function formatPrice(price) {
        return currency(price, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })
    }

    return (
        <div style={{ width: "48%" }} className="box-border flex flex-col p-1 pt-2 items-center bg-white border border-solid border-slate-500 flex-wrap cursor-pointer rounded-md noSelect" onClick={pressToProduct}>
            <div className="flex">
                <img src={srcImg} alt="" className="product" />
            </div>
            <div className="flex flex-col box-border w-full">
                <div className="text-lg font-bold">
                    {nameProduct}
                </div>
                <div className="truncate text-base">
                    {lowPrice ? formatPrice(lowPrice) : null} {highPrice ? ` ~ ${formatPrice(highPrice)}` : null}
                </div>
                <div className="font-semibold" style={{ color: entityName == "MUTIF" ? colors.main : 'black' }}>{entityName}</div>
            </div>
        </div>
    )
}

export default BoxItem