import { css } from "@emotion/react";
import { DotLoader } from "react-spinners";
import { colors } from "../../assets/colors";
import "./controlFlow.scss";
import { ToastContainer } from "react-toastify";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export const SplashScreen = () => {
  return (
    <div className="splashscreen">
      <ToastContainer />
      <DotLoader color={colors.main} loading={true} css={override} size={150} />
    </div>
  );
}

export const LogOutScreen = () => {
  return(
    <div className="logout">
      {/* Kasih gambar berhasil Logout */}
      <h4>Anda berhasil Logout</h4>
      <p>Untuk memulai lagi silahkan klik link di bawah ini</p>
      {/* Kasih Link untuk menuju ke login */}
    </div>
  )
}

export const ErrorScreen = () => {
  return(
    <div className="error-screen">
      <h4>ERROR!</h4>
      <p>Silahkan mulai ulang halaman ini!</p>
    </div>
  )
}