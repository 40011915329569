import currency from 'currency.js';
import { useState, useEffect, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useParams, useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import { createBrowserHistory } from "history";
import { ip } from '../../assets/api';
import { Navbar } from '../../components';
import Alert from '../../components/alert/Alert';
import { SplashScreen } from '../../components/controlFlow/ControlFlow';
import { DrawerDetil } from '../../components/drawwer/DrawerDetil';
import { QuestSizeUpdate } from '../../components/quest/Quest';
import { decrypt } from '../../components/tools/encrypt';
import { GlobalContext } from '../../context';
import { ADD_CART, LIST_PRODUK, REMOVE_CART } from '../../context/types';
import "./style/form.scss";

const FormProductUpdate = () => {
  const { dispatchCart } = useContext(GlobalContext);
  const [data, setData] = useState();
  const [discount, setDiscount] = useState(0);
  const [a1, setA1] = useState();
  const [a1Const, setA1Const] = useState();
  const [a2, setA2] = useState();
  const [a2Const, setA2Const] = useState();
  const [a3, setA3] = useState();
  const [a3Const, setA3Const] = useState();
  const [a4, setA4] = useState();
  const [a4Const, setA4Const] = useState();
  const [a5, setA5] = useState();
  const [a5Const, setA5Const] = useState();
  const [a6, setA6] = useState();
  const [a6Const, setA6Const] = useState();
  const [c7, setC7] = useState(); const [c13, setC13] = useState();
  const [c7Const, setC7Const] = useState(); const [c13Const, setC13Const] = useState();
  const [c8, setC8] = useState(); const [c14, setC14] = useState();
  const [c8Const, setC8Const] = useState(); const [c14Const, setC14Const] = useState();
  const [c9, setC9] = useState(); const [c15, setC15] = useState();
  const [c9Const, setC9Const] = useState(); const [c15Const, setC15Const] = useState();
  const [c10, setC10] = useState(); const [c16, setC16] = useState();
  const [c10Const, setC10Const] = useState(); const [c16Const, setC16Const] = useState();
  const [c11, setC11] = useState();
  const [c11Const, setC11Const] = useState();
  const [c12, setC12] = useState();
  const [c12Const, setC12Const] = useState();
  const [otherSize, setOtherSize] = useState();
  const [otherSizeConst, setOtherSizeConst] = useState();
  const [isVeil, setIsVeil] = useState();
  const [category, setCategory] = useState();
  const [buffqty, setBuffqty] = useState();
  const [price, setPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [colorChoose, setColorChoose] = useState();
  const [comboColor, setComboColor] = useState();
  const [isForgetSelectColor, setIsForgetSelectColor] = useState(false);

  // const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const history = createBrowserHistory();

  useEffect(() => {
    getCartIdUpdate();
  }, []);

  useEffect(() => {
    if (data) {
      calculatePrice();
    }
  }, [a1, a2, a3, a4, a5, a6, c7, c8, c9, c10, c11, c12, c13, c14, c15, c16, otherSize])

  async function SearchDataCart(clothes_id) {
    try {
      const data = sessionStorage.getItem('list');
      const decryptData = await decrypt(data, LIST_PRODUK);
      // const parseDataSession = await JSON.parse(data);
      // console.log(`Clothes ID ${clothes_id}`)
      // console.log(parseDataSession);
      if (decryptData) {
        setDiscount(decryptData?.discount)
        const searchProductId = await decryptData.data.find(e => e.id == clothes_id)
        if (searchProductId) {
          // console.log(searchProductId);
          setData(searchProductId);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', { autoClose: 900 })
    }
  }

  function getCartIdUpdate() {
    setIsLoading(true)
    const getPhoneLocal = localStorage.getItem('phone');

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${ip}/api/pre-order/cart/${getPhoneLocal}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log("Get Cart By Id");
        const cartDB = result.cart;
        dispatchCart({ type: ADD_CART, payload: cartDB })
        const findProduct = cartDB.find(e => e.id == params.idProduk);
        // console.log(findProduct)
        if (findProduct) {
          const clothes = findProduct.clothes;
          SearchDataCart(clothes.id);
          setBuffqty(clothes.buffer_product);
          setCategory(clothes.type_id);
          setIsVeil(findProduct.veil == 1 ? true : false);
          if (clothes.type_id == 2) {
            setA1(findProduct.size_s); setA2(findProduct.size_m); setA3(findProduct.size_l);
            setA1Const(findProduct.size_s); setA2Const(findProduct.size_m); setA3Const(findProduct.size_l);
            setA4(findProduct.size_xl); setA5(findProduct.size_xxl); setA6(findProduct.size_xxxl);
            setA4Const(findProduct.size_xl); setA5Const(findProduct.size_xxl); setA6Const(findProduct.size_xxxl);
            setColorChoose(findProduct.info);
            setIsLoading(false)
          } else if (clothes.type_id == 4) {
            setA1(findProduct.size_27); setA2(findProduct.size_28); setA3(findProduct.size_29);
            setA1Const(findProduct.size_27); setA2Const(findProduct.size_28); setA3Const(findProduct.size_29);
            setA4(findProduct.size_30); setA5(findProduct.size_31); setA6(findProduct.size_32);
            setA4Const(findProduct.size_30); setA5Const(findProduct.size_31); setA6Const(findProduct.size_32);
            setC7(findProduct.size_33); setC8(findProduct.size_34); setC9(findProduct.size_35);
            setC7Const(findProduct.size_33); setC8Const(findProduct.size_34); setC9Const(findProduct.size_35);
            setC10(findProduct.size_36); setC11(findProduct.size_37); setC12(findProduct.size_38);
            setC10Const(findProduct.size_36); setC11Const(findProduct.size_37); setC12Const(findProduct.size_38);
            setC13(findProduct.size_39); setC14(findProduct.size_40); setC15(findProduct.size_41);
            setC13Const(findProduct.size_39); setC14Const(findProduct.size_40); setC15Const(findProduct.size_41);
            setC16(findProduct.size_42)
            setC16Const(findProduct.size_42)
            setColorChoose(findProduct.info);
            setIsLoading(false);
          } else if (clothes.type_id == 5) {
            setOtherSize(findProduct.other)
            setOtherSizeConst(findProduct.other)
            setColorChoose(findProduct.info);
            setIsLoading(false);
          } else {
            // console.log("Produk anak-anak")
            setA1(findProduct.size_2); setA2(findProduct.size_4); setA3(findProduct.size_6);
            setA1Const(findProduct.size_2); setA2Const(findProduct.size_4); setA3Const(findProduct.size_6);
            setA4(findProduct.size_8); setA5(findProduct.size_10); setA6(findProduct.size_12);
            setA4Const(findProduct.size_8); setA5Const(findProduct.size_10); setA6Const(findProduct.size_12);
            setColorChoose(findProduct.info);
            setIsLoading(false)
          }
        } else {
          toast.error('Product not found', { autoClose: 900, onClose: () => navigate(-1) })
        }
      })
  }

  function sendUpdateApi({ isDelete }) {
    setIsLoading(true)
    const getPhoneLocal = localStorage.getItem('phone');
    /*       console.log(`
            Rekapan Data
            idProduk : ${data.id},
            idCart: ${params.idProduk},
            veil : 0,
            color: ${colorChoose},
            size_s", ${category == 1 && a1 ? a1 : 0},
            size_m", ${category == 1 && a2 ? a2 : 0},
            size_l", ${category == 1 && a3 ? a3 : 0},
            size_xl", ${category == 1 && a4 ? a4 : 0},
            size_xxl", ${category == 1 && a5 ? a5 : 0},
            size_xxxl", ${category == 1 && a6 ? a6 : 0},
            "size_2", ${category == 2 && a1 ? a1 : 0},
            "size_4", ${category == 2 && a2 ? a2 : 0},
            "size_6", ${category == 2 && a3 ? a3 : 0},
            "size_8", ${category == 2 && a4 ? a4 : 0},
            "size_10", ${category == 2 && a5 ? a5 : 0},
            "size_12", ${category == 2 && a6 ? a6 : 0},
            data: ${data},
            buffer_produk: ${buffqty},
            category: ${category},
          `) */

    let formdata = new FormData();
    formdata.append('_method', "put")
    formdata.append("clothes_id", data.id);
    formdata.append("info", colorChoose ? colorChoose : "-");
    formdata.append("veil", 0);

    // Baju
    formdata.append("size_s", category == 2 && a1 ? a1 : 0); formdata.append("size_2", (category == 1 || category == 3) && a1 ? a1 : 0);
    formdata.append("size_m", category == 2 && a2 ? a2 : 0); formdata.append("size_4", (category == 1 || category == 3) && a2 ? a2 : 0);
    formdata.append("size_l", category == 2 && a3 ? a3 : 0); formdata.append("size_6", (category == 1 || category == 3) && a3 ? a3 : 0);
    formdata.append("size_xl", category == 2 && a4 ? a4 : 0); formdata.append("size_8", (category == 1 || category == 3) && a4 ? a4 : 0);
    formdata.append("size_xxl", category == 2 && a5 ? a5 : 0); formdata.append("size_10", (category == 1 || category == 3) && a5 ? a5 : 0);
    formdata.append("size_xxxl", category == 2 && a6 ? a6 : 0); formdata.append("size_12", (category == 1 || category == 3) && a6 ? a6 : 0);

    // Celena
    formdata.append("size_27", category == 4 && a1 ? a1 : 0);
    formdata.append("size_28", category == 4 && a2 ? a2 : 0);
    formdata.append("size_29", category == 4 && a3 ? a3 : 0);
    formdata.append("size_30", category == 4 && a4 ? a4 : 0);
    formdata.append("size_31", category == 4 && a5 ? a5 : 0);
    formdata.append("size_32", category == 4 && a6 ? a6 : 0);
    formdata.append("size_33", category == 4 && c7 ? c7 : 0);
    formdata.append("size_34", category == 4 && c8 ? c8 : 0);
    formdata.append("size_35", category == 4 && c9 ? c9 : 0);
    formdata.append("size_36", category == 4 && c10 ? c10 : 0);
    formdata.append("size_37", category == 4 && c11 ? c11 : 0);
    formdata.append("size_38", category == 4 && c12 ? c12 : 0);
    formdata.append("size_39", category == 4 && c13 ? c13 : 0);
    formdata.append("size_40", category == 4 && c14 ? c14 : 0);
    formdata.append("size_41", category == 4 && c15 ? c15 : 0);
    formdata.append("size_42", category == 4 && c16 ? c16 : 0);

    // Aksesoris
    formdata.append("other", category == 5 && otherSize ? otherSize : 0);
    formdata.append("total", price);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      mode: 'cors',
    };

    fetch(`${ip}/api/pre-order/${getPhoneLocal}/cart/${params.idProduk}`, requestOptions)
      .then(async response => response.json())
      .then(result => {
        // console.log(result);
        if (result.status == "success") {
          if (isDelete) {
            deleteStuff(params.idProduk);
          } else {
            getCartIdUpdate();
          }
          toast.success(result.message, { autoClose: 900, onClose: () => setIsLoading(false) })
        } else {
          toast.error(result.message, { autoClose: 1300, onClose: () => setIsLoading(false) })
          console.log(result);
        }
      })
      .catch(error => {
        toast.error(error.message, { autoClose: 2000, onClose: () => setIsLoading(false) })
        setIsLoading(false);
        console.log(error)
      });
  }

  function deleteStuff(id) {
    const phone = localStorage.getItem('phone');
    let formdata = new FormData();
    formdata.append("_method", "delete")

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      mode: 'cors'
    };

    fetch(`${ip}/api/pre-order/${phone}/clothes/${id}`, requestOptions)
      .then(res => res.json())
      .then(response => {
        // console.log(response)
        if (response.status == "success") {
          dispatchCart({ type: REMOVE_CART, payload: id })
          toast.success(response.message, {
            autoClose: 900, onClose: () => navigate(-1, {replace: true})});
        } else {
          toast.error(response.message, { autoClose: 1500 })
        }
      })
      .catch(error => { console.error(e); toast.error(error.message, { autoClose: 900 }) })
  }

  function limitInput(idBuffer, input, constant) {
    try {
      const intBuff = parseInt(buffqty[idBuffer].qty_buffer);
      const intAvail = parseInt(buffqty[idBuffer].qty_avaliable);
      const intConst = parseInt(constant);

      if (intBuff != 0) {
        if (intAvail != 0) {
          if (input <= intAvail) {
            return input
          } else {
            return intAvail + intConst
          }
        } else {
          return input
        }
      } else {
        return input
      }
    } catch (e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', { autoClose: 900 })
    }
  }

  function calculatePrice() {
    try {
      const dataPrice = category == 2 ? [
        a1 ? a1 * data.size_s : 0,
        a2 ? a2 * data.size_m : 0,
        a3 ? a3 * data.size_l : 0,
        a4 ? a4 * data.size_xl : 0,
        a5 ? a5 * data.size_xxl : 0,
        a6 ? a6 * data.size_xxxl : 0
      ] : category == 4 ? [
        !a1 ? 0 : a1 * data.size_27,
        !a2 ? 0 : a2 * data.size_28,
        !a3 ? 0 : a3 * data.size_29,
        !a4 ? 0 : a4 * data.size_30,
        !a5 ? 0 : a5 * data.size_31,
        !a6 ? 0 : a6 * data.size_32,
        !c7 ? 0 : c7 * data.size_33,
        !c8 ? 0 : c8 * data.size_34,
        !c9 ? 0 : c9 * data.size_35,
        !c10 ? 0 : c10 * data.size_36,
        !c11 ? 0 : c11 * data.size_37,
        !c12 ? 0 : c12 * data.size_38,
        !c13 ? 0 : c13 * data.size_39,
        !c14 ? 0 : c14 * data.size_40,
        !c15 ? 0 : c15 * data.size_41,
        !c16 ? 0 : c16 * data.size_42,
      ] : category == 5 ? [
        otherSize ? otherSize * data.other : 0
      ] : [
        !a1 ? 0 : a1 * data.size_2[0],
        !a2 ? 0 : a2 * data.size_4[0],
        !a3 ? 0 : a3 * data.size_6[0],
        !a4 ? 0 : a4 * data.size_8[0],
        !a5 ? 0 : a5 * data.size_10[0],
        !a6 ? 0 : a6 * data.size_12[0]
      ]

      let totalPrice = 0;

      for (let i = 0; i < dataPrice.length; i++) {
        const casePrice = currency(totalPrice).add(dataPrice[i]).value;
        totalPrice = casePrice;
      }

      const discountCase = totalPrice * (discount / 100);
      const finalPrice = totalPrice - discountCase;
      if (totalPrice !== NaN) {
        setPrice(finalPrice);
      }
    } catch (e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', { autoClose: 900 })
    }
  }

  function alertConfirmDelete() {
    confirmAlert({
      customUI: ({ onClose, }) => {
        return <Alert
          titleAlert={"Peringatan"}
          isAlert
          contentAlert={`Anda tidak menginputkan produk ini!`}
          questAlert={`Apakah anda ingin menghapus produk ini dari keranjang?`}
          txtNo={"tidak"}
          txtYes={"ya"}
          pressNo={() => {
            onClose()
            setIsLoading(false)
          }}
          pressYes={() => {
            // sendUpdateApi({isDelete: true});
            deleteStuff(params?.idProduk);
            onClose();
          }}
        />
      }
    })
  }

  function validationSend() {
    if (price == 0) {
      alertConfirmDelete()
    } else {
      sendUpdateApi({ isDelete: false });
    }
  }

  return data ? (
    <div className="w-screen flex flex-col justify-center items-center font-sans">
      <div className='w-full lg:w-1/3 flex flex-col justify-center items-center'>
        <div className="h-11 w-full">
          <Navbar isDashboard={false} pressBack={() => navigate(-1, { replace: true })} />
        </div>
        <ToastContainer />
        <div className="flex flex-col items-center justify-center p-5">
          <div className="flex flex-col justify-center box-border">
            <DrawerDetil data={data} comboColor={colorChoose ? colorChoose : null} discount={discount} brand={data.article_name} />
          </div>
          <div className="w-full flex flex-col items-center justify-center">
            <QuestSizeUpdate
              discount={discount}
              category={data.type_id}
              onChange1={e => setA1(limitInput(0, e.target.value, a1Const))} value1={a1}
              onChange2={e => setA2(limitInput(1, e.target.value, a2Const))} value2={a2}
              onChange3={e => setA3(limitInput(2, e.target.value, a3Const))} value3={a3}
              onChange4={e => setA4(limitInput(3, e.target.value, a4Const))} value4={a4}
              onChange5={e => setA5(limitInput(4, e.target.value, a5Const))} value5={a5}
              onChange6={e => setA6(limitInput(5, e.target.value, a6Const))} value6={a6}
              onChange7={e => setC7(limitInput(6, e.target.value, c7Const))} value7={c7}
              onChange8={e => setC8(limitInput(7, e.target.value, c8Const))} value8={c8}
              onChange9={e => setC9(limitInput(8, e.target.value, c9Const))} value9={c9}
              onChange10={e => setC10(limitInput(9, e.target.value, c10Const))} value10={c10}
              onChange11={e => setC11(limitInput(10, e.target.value, c11Const))} value11={c11}
              onChange12={e => setC12(limitInput(11, e.target.value, c12Const))} value12={c12}
              onChange13={e => setC13(limitInput(12, e.target.value, c13Const))} value13={c13}
              onChange14={e => setC14(limitInput(13, e.target.value, c14Const))} value14={c14}
              onChange15={e => setC15(limitInput(14, e.target.value, c15Const))} value15={c15}
              onChange16={e => setC16(limitInput(15, e.target.value, c16Const))} value16={c16}
              onChangeOther={e => setOtherSize(limitInput(0, e.target.value, otherSizeConst))} valueOther={otherSize}
              optionsColor={comboColor ? comboColor : null}
              onChangeColor={e => setColorChoose(e.value)}
              valueColor={colorChoose}
              isForgetSelectColor={isForgetSelectColor}
              stock={buffqty}
              price={price}
              price1={category == 2 ? data.size_s : category == 4 ? data.size_27 : data.size_2[0]} price2={category == 2 ? data.size_m : category == 4 ? data.size_28 : data.size_4[0]}
              price3={category == 2 ? data.size_l : category == 4 ? data.size_29 : data.size_6[0]} price4={category == 2 ? data.size_xl : category == 4 ? data.size_30 : data.size_8[0]}
              price5={category == 2 ? data.size_xxl : category == 4 ? data.size_31 : data.size_10[0]} price6={category == 2 ? data.size_xxxl : category == 4 ? data.size_32 : data.size_12[0]}
              price7={category == 4 ? data.size_33 : 0} price8={category == 4 ? data.size_34 : null} price9={category == 4 ? data.size_35 : null} price10={category == 4 ? data.size_36 : null}
              price11={category == 4 ? data.size_37 : null} price12={category == 4 ? data.size_38 : null} price13={category == 4 ? data.size_39 : null} price14={category == 4 ? data.size_40 : null}
              price15={category == 4 ? data.size_41 : null} price16={category == 4 ? data.size_42 : null}
              priceOther={data.other}
              entity={data.entity_name}
              isVeil={isVeil}
              pressToVeil={() => navigate(`/katalog/produk/${idProduk ? idProduk : location.state.idProduk}/veil`)}
              isReadOnly={isLoading}
            />
            <button className="rounded text-white text-2xl font-bold w-full py-2 cursor-pointer mt-5 bg-editbtn active:bg-editBtnActive" type="submit" onClick={() => validationSend()} disabled={isLoading} >
              {isLoading ? <BeatLoader color="white" /> : 'edit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : <SplashScreen />
}

export default FormProductUpdate;