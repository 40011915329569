import NoStuffIMG from '../../assets/img/EmptyStuff.png';
import "./nostuff.scss";

export const NoStuff = () => {
    return (
        <div className="nostuff-container">
            <div className="wrapper-nostuff">
                <div className="img-nostuff">
                    <img src={NoStuffIMG} alt="Gambar Tidak Ada Barang" />
                </div>
                <div className="txt-nostuff">
                    Barang Kosong
                </div>
            </div>
        </div>
    )
}

export const NoStuffCart = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full">
            <img className='w-3/4 md:w-1/2' src={NoStuffIMG} alt="Gambar Tidak Ada Barang" />
            <div className="text-center text-xl font-bold text-main">
                Keranjang Kosong
            </div>
        </div>
    )
}
