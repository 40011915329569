import CryptoJS from 'crypto-js';

export function encrypt(rawData, key) {
    let encrypt = CryptoJS.AES.encrypt(JSON.stringify(rawData), key);
    return encrypt;
}

export async function decrypt(encryptedData, key) {
    let decrypt = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
    return await JSON.parse(decrypt);
}