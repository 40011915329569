import { Navbar } from "../../components";
import { IoIosHome as IconHome } from "react-icons/io";
import { IoStorefront as IconStore } from "react-icons/io5";
import { useLocation, useParams } from "react-router-dom";
import "./style/formAddress.scss";
// import DummyAvatar from '../../assets/img/dummy_img/avatar.png';
import { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ip, ip_dev, ip_region } from "../../assets/api";
import { toast, ToastContainer } from "react-toastify";
// import Modal from 'react-awesome-modal';
import { SplashScreen } from "../../components/controlFlow/ControlFlow";
import { colors } from "../../assets/colors";

const UpdateProfile = () => {
  const [phoneConst, setPhoneConst] = useState("");
  const [name, setName] = useState();
  const [role, setRole] = useState("");
  const [zip, setZip] = useState();
  const [phone, setPhone] = useState("");
  const [dataProvince, setDataProvince] = useState([]);
  const [province, setProvince] = useState("");
  const [dataRegency, setDataRegency] = useState();
  const [regency, setRegency] = useState("");
  const [dataDistrict, setDataDistrict] = useState();
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [addressType, setAddressType] = useState();
  const [statusStore, setStatusStore] = useState();
  const [dateOpenStore, setDateOpenStore] = useState();
  const [msdpMS, setMSDPMS] = useState();
  const [noFax, setNoFax] = useState();
  const [codeToko, setCodeStore] = useState();
  const [isLoadingRegency, setIsLoadingRegency] = useState(false);
  const [isLoadingDistrict, setIsLoadingDistrict] = useState(false);
  const [comment, setComment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [code1, setCode1] = useState(); const [code2, setCode2] = useState(); const [code3, setCode3] = useState(); const [code4, setCode4] = useState();
  const [isEditStore, setIsEditStore] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [isEditDate, setIsEditDate] = useState(false);
  const [idStore, setIdStore] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    getDataProvince();
    const stateStore = location.state?.store;
    const stateEditeStore = location.state?.isEditStore;
    const stateEditUser = location.state?.user;
    const stateRole = location.state?.role;
    const phone = localStorage.getItem("phone");
    
    if(stateEditeStore) {
      setIsEditDate(stateEditeStore);
      setIsEditStore(true);
      setIsEditUser(false);
      getDataStateStore(stateStore);
    }
    
    if(stateEditUser) {
      getDataStateUser(stateEditUser)
      setIsEditStore(false);
      setIsEditUser(true);
      setIsEditDate(stateEditUser);
    }

    if(stateRole){
      setRole(stateRole)
    }

    if(phone) {
      setPhoneConst(phone)
    }
  }, []);

  useEffect(() => {
    if(code1 || code2 || code3 || code4) {
      // let format = `${code1}/MS/${code2}/${code3}/${code4}`;
      setCodeStore(`${code1}/MS/${code2}/${code3}/${code4}`)
    }
  }, [code1, code2, code3, code4]);

  useEffect(() => {
    console.log(dateOpenStore)
  }, [dateOpenStore])

  function validationSendDataStore(isUpdateStore) {
    try{
      if(name.length == 0) {
        toast.warn("Nama Toko belum diisi!", {autoClose: 1200});
      } else if(codeToko.length == 0) {
        toast.warn(`${role == 2 ? "Kode MS" : role == 3 ? "Kode PM" : "Kode Toko"} belum diisi!`, {autoClose: 1200});
      } else if(dateOpenStore.length == 0) {
        toast.warn("Tanggal buka belum diisi!", {autoClose: 1200});
      } else if(statusStore.length == 0) {
        toast.warn("Status toko belum diisi!", {autoClose: 1200});
      } else if(phone.length == 0) {
        toast.warn(`Nomor HP ${role == 2 ? "MS" : role == 3 ? "PM" : "DB"} belum diisi!` , {autoClose: 1200});
      } else if(msdpMS.length == 0) {
        toast.warn("msdpMS belum diisi!", {autoClose: 1200});
      } else if(address.length == 0) {
        toast.warn("Alamat belum diisi!", {autoClose: 1200});
      } else if(province.length == 0) {
        toast.warn("Provinsi belum diisi!", {autoClose: 1200});
      } else if(regency.length == 0){
        toast.warn("Kota/Kabupaten belum diisi!", {autoClose: 1200});
      } else if(district.length == 0) {
        toast.warn("Kecamatan belum diisi!", {autoClose: 1200});
      } else if(zip.length == 0) {
        toast.warn("Kode Pos belum diisi!", {autoClose: 1200});
      } else if(noFax.length == 0) {
        toast.warn("Kode Fax belum diisi!", {autoClose: 1200});
      } else if(isUpdateStore) {
        updateStore()
      } else {
        registStore()
      }
    } catch(e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', {autoClose: 900})
    }
  }

  function validationSendDataUser(isUpdateUser) {
    try{
      if(phone.length == 0) {
        toast.warn(`Nomor HP ${role == 2 ? "MS" : role == 3 ? "PM" : "DB"} belum diisi!` , {autoClose: 1200});
      } else if(address.length == 0) {
        toast.warn("Alamat belum diisi!", {autoClose: 1200});
      } else if(province.length == 0) {
        toast.warn("Provinsi belum diisi!", {autoClose: 1200});
      } else if(regency.length == 0){
        toast.warn("Kota/Kabupaten belum diisi!", {autoClose: 1200});
      } else if(district.length == 0) {
        toast.warn("Kecamatan belum diisi!", {autoClose: 1200});
      } else if(zip.length == 0) {
        toast.warn("Kode Pos belum diisi!", {autoClose: 1200});
      } else if(noFax.length == 0) {
        toast.warn("Kode Fax belum diisi!", {autoClose: 1200});
      } else if(isUpdateUser) {
        updateOwnAddress();
      } else {
        registOwnAddress();
      }
    } catch(e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', {autoClose: 900})
    }
  }

  function getDataStateStore(data){
    try{
      const storeAddress = data?.mutif_store_address[0];
        setIdStore(data?.id);
        setName(data?.mutif_store_name);
        setStatusStore(data?.status);
        setMSDPMS(data?.msdp)
        setProvince(storeAddress?.province);
        setRegency(storeAddress?.regency);
        setDistrict(storeAddress?.district);
        setZip(storeAddress?.zip);
        setAddress(storeAddress?.address);
        setPhone(storeAddress?.phone_2);
        setNoFax(storeAddress?.fax_1);
        setCodeStore(data?.mutif_store_code);
        setDateOpenStore(moment(data?.openDate).format("YY-MM-DD"))
        if(storeAddress?.comment != 'undefined') {
          setComment(storeAddress?.comment);
        }
        
        if(String(storeAddress?.addr_type).includes("ship")) {
          setAddressType('Pengiriman');
        } else {
          setAddressType('Pembayaran');
        }
    } catch(e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', {autoClose: 900})
    }
  }

  function getDataStateUser(data) {
    try{
      console.log(data)
      setProvince(data?.province);
      setRegency(data?.regency);
      setDistrict(data?.district);
      setAddress(data?.address);
      setZip(data?.zip);
      setNoFax(data?.fax_1);
      setPhone(data?.phone_2);
    } catch(e) {
      console.error(e);
      toast.error('Ada Error! Silahkan coba lagi', {autoClose: 900})
    }
  }

  function registStore() {
    setIsLoading(true);
    var formdata = new FormData();
    formdata.append("ms_ms_name", name);
    formdata.append("ms_code", codeToko);
    formdata.append("open_date", dateOpenStore);
    formdata.append("status", statusStore);
    formdata.append("msdp", msdpMS);
    formdata.append("address", address);
    formdata.append("province", province);
    formdata.append("regency", regency);
    formdata.append("district", district);
    formdata.append("phone", phone);
    formdata.append("fax", noFax);
    formdata.append("addr_type", addressType);
    formdata.append("zip", zip);
    formdata.append("comment", comment ? comment : "~");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ip}/api/mutif-store/${phoneConst}`, requestOptions)
      .then(async response => {
        console.log(response);
        const result = await response.json();
        console.log(result);
        if(result?.status == "success") {
          toast.success(result.message, { autoClose: 900, onClose: () => navigate(-1) });
        } else {
          toast.error(result.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
        }
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
      });
  }

  function updateStore() {
    setIsLoading(true)
    var formdata = new FormData();
    formdata.append("_method", "put");
    formdata.append("ms_ms_name", name);
    formdata.append("ms_code", codeToko);
    formdata.append("open_date", dateOpenStore);
    formdata.append("status", statusStore);
    formdata.append("msdp", msdpMS);
    formdata.append("address", address);
    formdata.append("province", province);
    formdata.append("regency", regency);
    formdata.append("district", district);
    formdata.append("phone", phone);
    formdata.append("fax", noFax);
    formdata.append("addr_type", addressType);
    formdata.append("zip", zip);
    formdata.append("comment", comment ? comment : "~");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ip}/api/mutif-store/${phoneConst}/store/${idStore}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result?.status == "success") {
          toast.success(result.message, { autoClose: 900, onClose: () => navigate(-1) });
        } else {
          toast.error(result.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
        }
      })
      .catch(error => console.log('error', error));
  }

  function registOwnAddress() {
    setIsLoading(true);
     var formdata = new FormData();
    formdata.append("address", address);
    formdata.append("district", district);
    formdata.append("regency", regency);
    formdata.append("province", province);
    formdata.append("phone_2", phone);
    formdata.append("fax_1", noFax);
    formdata.append("zip", zip);
    // formdata.append("addr_type", "billTo");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ip}/api/client/create-address/${phoneConst}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status == "success") {
          toast.success(result.message, { autoClose: 900, onClose: () => {navigate(-1); setIsLoading(false)} });
        } else {
          toast.error(result.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
        }
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
      });
  }

  function updateOwnAddress() {
    setIsLoading(true);
    var formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("address", address);
    formdata.append("district", district);
    formdata.append("regency", regency);
    formdata.append("province", province);
    formdata.append("phone_2", phone);
    formdata.append("fax_1", noFax);
    formdata.append("zip", zip);
    formdata.append("addr_type", "billTo");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ip}/api/client/update-address/${phoneConst}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status == "success") {
          console.log(result)
          toast.success(result.message, { autoClose: 900, onClose: () => navigate(-1) });
        } else {
          console.log(result)
          toast.error(result.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
        }
      })
      .catch(error => {
        console.error(error)
        toast.error(error.message, {autoClose: 1500, onClose: () => setIsLoading(false)});
      });
  }

  function getDataProvince() {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`https://bagussona.github.io/api-wilayah-indonesia/api/provinces.json`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        let wadah = [];

        result.map((value) => {
          let casing = {
            ...value,
            label: value.name,
            value: value.id
          };
          wadah.push(casing);
        });
        setDataProvince(wadah);
      })
      .catch((e) => {console.error(e); toast.error(e.message, { autoClose: 2000 })});
  }

  function getDataCity(city) {
    setIsLoadingRegency(true);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`https://bagussona.github.io/api-wilayah-indonesia/api/regencies/${city}.json`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setIsLoadingRegency(false);
        const data = result;
        let wadah = [];
        if (data) {
          data.map((value) => {
            let casing = {
              ...value,
              label: value.name,
              value: value.id
            };
            wadah.push(casing);
          });
          setDataRegency(wadah);
        }
      })
      .catch((e) => {console.error(e); toast.error(e.message, { autoClose: 2000 })});
  }

  function getDataDistrict(regency) {
    setIsLoadingDistrict(true);
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`https://bagussona.github.io/api-wilayah-indonesia/api/districts/${regency}.json`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        setIsLoadingDistrict(false);
        const data = result;
        let wadah = [];

        if (data) {
          data.map((value) => {
            let casing = {
              ...value,
              label: value.name,
              value: value.id
            };
            wadah.push(casing);
          });
          setDataDistrict(wadah);
        }
      })
      .catch((e) => {console.error(e); toast.error(e.message, { autoClose: 2000 })});
  }

  return isLoading ? <SplashScreen /> : (
    <div className="formAddress-container">
      <div className="navbar">
        <Navbar pressBack={() => navigate(-1, {replace: true})} nama_db="Form Alamat" />
      </div>
      <ToastContainer />
      <div className="wrapper-address">
        {params.kindAddress == "user" ? (
          <div className="box-form">
          <TitleScreen route={params.kindAddress} />
          <div className="parent-form">
            <div className="content-form">
              <div className="input-form">
                <div className="input-wrapper-address">
                  <>
                  <InputAddres
                    label={"Provinsi"}
                    isSelectOption
                    isSearchable
                    selectValue={province}
                    onChangeSelect={(e) => {
                      setProvince(e.name);
                      getDataCity(e.value);
                    }}
                    options={dataProvince}
                    value={province}
                    placeholder={province}
                    />
                  <div className="address-indo">
                      <div className="segment-add-indo">
                        <InputAddres
                          label={"Kota/Kab"}
                          isSelectOption
                          isSearchable
                          isDataOption={!Array.isArray(dataRegency) && !regency}
                          selectValue={regency}
                          onChangeSelect={(e) => {
                            setRegency(e.name);
                            getDataDistrict(e.value);
                          }}
                          options={dataRegency}
                          value={regency}
                          isLoadingSelect={isLoadingRegency}
                          placeholder={regency}
                          />
                      </div>
                      <div className="segment-add-indo">
                        <InputAddres
                          label={"Kecamatan"}
                          isSelectOption
                          isSearchable
                          isDataOption={!Array.isArray(dataDistrict) && !district}
                          selectValue={district}
                          onChangeSelect={(e) => {
                            setDistrict(e.name);
                          }}
                          options={dataDistrict}
                          value={district}
                          isLoadingSelect={isLoadingDistrict}
                          placeholder={district}
                        />
                      </div>
                    </div>
                    <InputAddres
                      isTextArea
                      label={"Alamat"}
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      placeholder={address}
                    />
                    <div className="address-indo">
                      <div className="segment-add-indo">
                      <InputAddres
                          label={"Kode Pos"}
                          type={"number"}
                          onChange={(e) => setZip(e.target.value)}
                          placeholder={zip}
                          />
                      </div>
                      <div className="segment-add-indo">
                        <InputAddres
                          label={"Nomor FAX"}
                          type={"number"}
                          onChange={(e) => setNoFax(e.target.value)}
                          value={noFax}
                          placeholder={noFax}
                        />
                      </div>
                    </div>
                    <InputAddres
                      label={"Nomor HP"}
                      type={"tel"}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder={phone}
                    />
                  </>
                  
                  {isEditUser ? (
                    <div className="btn-setAddress save-address" onClick={() => validationSendDataUser(true)}>
                      <button style={{backgroundColor: colors.edit}}>Edit</button>
                    </div>
                  ) : (
                    <div className="btn-setAddress save-address" onClick={() => validationSendDataUser(false)}>
                      <button>Kirim</button>
                    </div>
                  )}        
                </div>
              </div>
            </div>
          </div>
        </div>
        ) : (
          /* Form Toko */
          <div className="box-form">
            <TitleScreen route={params.kindAddress} />
            <div className="parent-form">
              <div className="content-form">
                <div className="input-form">
                  <div className="input-wrapper-address">
                    <>
                      <InputAddres
                        label={"Nama Toko"}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      {
                        isEditStore ? (
                          <InputAddres
                            label={role == 2 ? "Kode MS" : role == 3 ? "Kode PM" : "Kode Toko DB"}
                            type="text"
                            onChange={(e) => setCodeStore(e.target.value)}
                            value={codeToko}
                          />
                        ) : (
                          <InputAddres
                            label={role == 2 ? "Kode MS" : role == 3 ? "Kode PM" : "Kode Toko DB"}
                            onChange={(e) => setCodeStore(e.target.value)}
                            value={codeToko}
                            isMS={ role == 2 ? true : false}
                            type={'number'}
                            onChangeMS1={e => setCode1(e.target.value)}
                            onChangeMS2={e => setCode2(e.target.value)}
                            onChangeMS3={e => setCode3(e.target.value)}
                            onChangeMS4={e => setCode4(e.target.value)}
                          />
                        )
                      }
                      <InputAddres
                          label={role == 2 ? "Nomor HP MS" : role == 3 ? "Nomor HP PM" : "Nomor HP DB"}
                          type={"tel"}
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      <InputAddres
                        isTextArea
                        label={"Alamat"}
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                      <div className="address-indo">
                        <div className="segment-add-indo">
                          <InputAddres label={"Tipe Alamat"} isSelectOption options={tipeAlamat} onChangeSelect={e => setAddressType(e.value)} isSearchable={false} placeholder={addressType} />
                        </div>
                        <div className="segment-add-indo">
                          <InputAddres label={"Status"} isSelectOption isSearchable={false} options={statusToko} onChangeSelect={e => setStatusStore(e.value)} placeholder={statusStore} />
                        </div>
                      </div>
                      <div className="address-indo">
                        <div className="segment-add-indo">
                          <InputAddres
                            label={"Tanggal Buka"}
                            type={"date"}
                            onChange={(e) => setDateOpenStore(moment(e.target.value).format("YY-MM-DD"))}
                            isEdit={isEditDate}
                            placeholder={dateOpenStore}
                            onPress={() => setIsEditDate(false)}
                          />
                        </div>
                        <div className="segment-add-indo">
                          <InputAddres label={"MSDP MS"} isSelectOption options={msDP} isSearchable={false} onChangeSelect={e => setMSDPMS(e.value)} placeholder={msdpMS} />
                        </div>
                      </div>
                        <InputAddres
                          label={"Provinsi"}
                          isSearchable
                          isSelectOption
                          selectValue={province}
                          onChangeSelect={(e) => {
                            setProvince(e.name);
                            getDataCity(e.value);
                          }}
                          options={dataProvince}
                          value={province}
                          placeholder={province}
                        />
                      <div className="address-indo">
                        <div className="segment-add-indo">
                          <InputAddres
                            label={"Kota/Kab"}
                            isSearchable
                            isSelectOption
                            isDataOption={!(Array.isArray(dataRegency)) && !isEditStore}
                            selectValue={regency}
                            onChangeSelect={(e) => {
                              setRegency(e.name);
                              getDataDistrict(e.value);
                            }}
                            options={dataRegency}
                            value={regency}
                            isLoadingSelect={isLoadingRegency}
                            placeholder={regency}
                          />
                        </div>
                        <div className="segment-add-indo">
                          <InputAddres
                            label={"Kecamatan"}
                            isSearchable
                            isSelectOption
                            isDataOption={!(Array.isArray(dataDistrict)) && !isEditStore}
                            selectValue={district}
                            onChangeSelect={(e) => {
                              setDistrict(e.name);
                            }}
                            options={dataDistrict}
                            value={district}
                            isLoadingSelect={isLoadingDistrict}
                            placeholder={district}
                          />
                        </div>
                      </div>
                      <div className="address-indo">
                        <div className="segment-add-indo">
                        <InputAddres
                            label={"Kode Pos"}
                            type={'number'}
                            onChange={(e) => setZip(e.target.value)}
                            value={zip}
                          />
                        </div>
                        <div className="segment-add-indo">
                          <InputAddres
                            label={"Nomor FAX"}
                            type={"number"}
                            onChange={(e) => setNoFax(e.target.value)}
                            value={noFax}
                          />
                        </div>
                      </div>
                      <InputAddres
                        label={"Catatan"}
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        placeholder={comment}
                      />
                    </>
                 
                    {isEditStore ? (
                      <div className="btn-setAddress save-address" onClick={() => validationSendDataStore(true)}>
                        <button style={{backgroundColor: colors.edit}}>Edit</button>
                      </div>
                    ) : (
                      <div className="btn-setAddress save-address" onClick={() => validationSendDataStore(false)}>
                        <button>Kirim</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) 
};

export default UpdateProfile;

const TitleScreen = ({ route }) => {
  return (
    <div className="title-form">
      {route == "user" ? <IconHome /> : <IconStore />}
      <span>{route == "user" ? "Alamat Pribadi" : "Alamat Toko"}</span>
    </div>
  );
};

function limitInputMS(e) {
  if (e.target.value.length > e.target.maxLength)
  e.target.value = e.target.value.slice(0,e.target.maxLength);
}

const InputAddres = ({
  label,
  isSelectOption,
  isTextArea,
  isCheckBox,
  isSearchable,
  onChange,
  value,
  options,
  onChangeSelect,
  selectValue,
  isLoadingSelect,
  type,
  placeholder,
  isDataOption, 
  isMS,
  onChangeMS1, onChangeMS2, onChangeMS3, onChangeMS4,
  isEdit,
  onPress
}) => {
  return (
    <div className="input-address" style={{ display: isDataOption ? "none" : null }}>
      <div className="quest-wrapper">
        <label htmlFor="">{label}</label>
      </div>
      {isSelectOption ? (
        <div className="dropdown">
          <Select
            defaultValue={selectValue}
            onChange={onChangeSelect}
            options={options}
            isSearchable={isSearchable}
            value={selectValue}
            defaultInputValue={selectValue}
            autoFocus
            isLoading={isLoadingSelect}
            placeholder={placeholder}
          />
        </div>
      ) : isTextArea ? (
        <textarea rows={2} onChange={onChange} value={value} placeholder={placeholder} />
      ) : isCheckBox ? (
        <input className="check-box" type="checkbox" id="switch" />
      ) : isMS ? (
        <div className="inputCode">
          <ul>
            <li>
              <input placeholder="..." type={"number"} maxLength="4" onInput={e => limitInputMS(e)} onChange={onChangeMS1} />/
            </li>
            <li>
              <p>MS</p>
            </li>
            <li>
              /<input placeholder="..." type={"number"} maxLength="2" onInput={e => limitInputMS(e)} onChange={onChangeMS2} />
            </li>
            <li>
              /<input placeholder="..." type={"number"} maxLength="2" onInput={e => limitInputMS(e)} onChange={onChangeMS3} />
            </li>
            <li>
              /<input placeholder="..." type={"number"} maxLength="2" onInput={e => limitInputMS(e)} onChange={onChangeMS4} />
            </li>
          </ul>
        </div>
      ) : (
        <input type={!isEdit ? type : 'text'} onChange={onChange} placeholder={placeholder} value={value} onClick={onPress} />
      )}
    </div>
  );
};

const tipeAlamat = [
  { label: "Pembayaran", value: "billTo" },
  { label: "Pengiriman", value: "shipTo" },
];

const statusToko = [
  { label: "Aktif", value: "Active" },
  { label: "Tutup", value: "Closed" },
  { label: "Hold GO", value: "Hold GO" },
  { label: "On Process", value: "On Process" },
]

const msDP = [
  { label: "Ya", value: "yes" },
  { label: "Biarkan Kosong", value: "-" },
]

    // console.log(`
    //   ms_ms_name: ${name},
    //   ms_code: ${codeToko},
    //   open_date: ${dateOpenStore},
    //   status: ${statusStore},
    //   msdp: ${msdpMS},
    //   address: ${address},
    //   province: ${province},
    //   regency: ${regency},
    //   district: ${district},
    //   phone: ${phone},
    //   noFax: ${noFax},
    //   addr_type: ${addressType},
    //   zip: ${zip},
    //   comment: ${comment},
    // `)