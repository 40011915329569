import { useContext } from 'react';
import { IoMdCart as Cart, IoMdArrowBack as ArrowBack, IoIosLogOut as Logout, IoIosHome as IconHome } from 'react-icons/io'
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context';
import { colors } from '../../assets/colors';

type IconProps = {
    nama_db?: string;
    isDashboard: boolean;
    isUpdateItem?: boolean;
    isNotHaveAccess?: boolean;
    isCart?: boolean;
    pressBack?: any;
    pressHome?: any;
    customNavCart?: React.MouseEventHandler<HTMLDivElement>;
}

const Navbar: React.FC<IconProps> = ({ isDashboard, isUpdateItem, isCart, nama_db, pressBack, pressHome, isNotHaveAccess, customNavCart }) => {
    const { cart }: any = useContext(GlobalContext);
    const colorNavbar = nama_db == "MUTIF" ? colors.main : nama_db == "DAMOZA" ? colors.entDamoza : nama_db == "UPMORE" ? colors.entUpmore : colors.main;
    const txtNavbar = nama_db == "DAMOZA" ? 'black' : 'white';

    const navigate = useNavigate();

    return (
        <div className="w-full h-full font-sans">
            <div className="h-full flex shadow-md text-xl items-center justify-between pl-3 pr-1" style={{ backgroundColor: colorNavbar }}>
                {pressHome ? (
                    <div onClick={pressHome}>
                        <div className='items' style={{ marginRight: "10px", visibility: isDashboard ? 'hidden' : 'visible' }}>
                            <IconHome color={txtNavbar} className='icon' />
                        </div>
                    </div>
                ) : (
                    <div onClick={pressBack}>
                        <div className='items' style={{ marginRight: "10px", visibility: isDashboard ? 'hidden' : 'visible' }}>
                            <ArrowBack color={txtNavbar} className='icon' />
                        </div>
                    </div>

                )}
                <div className='text-xl font-semibold' style={{ color: txtNavbar }}>
                    {nama_db ? <p>{nama_db}</p> : <p>{isCart ? "Keranjang" : isUpdateItem ? "Edit Produk" : nama_db == "" ? "Loading..." : nama_db}</p>}
                </div>
                {isNotHaveAccess ? null : <div className='cursor-pointer flex items-center box-border text-xl noSelect' onClick={customNavCart ? customNavCart : () => navigate('/cart')} style={{ marginRight: "10px", visibility: isUpdateItem || isCart ? 'hidden' : 'visible', color: txtNavbar }} >
                    {/* <div style={{ backgroundColor: nama_db == "DAMOZA" ? "rgba(0,0,0,0.5)" : nama_db == "UPMORE" ? colors.entDamoza : "RGBA(255, 192, 203, 0.6)", color: nama_db == 'UPMORE' ? 'black' : 'white' }} className="px-2 rounded-full text-lg">{Array.isArray(cart) ? cart.length : "0"}</div> */}
                    <div className="px-2 rounded-full text-lg text-white bg-btnDisable">{Array.isArray(cart) ? cart.length : "0"}</div>
                    <Cart color={txtNavbar} className='text-xl' />
                </div>}
            </div>
        </div>
    )
}

export default Navbar;