import './button.scss';
import { AiOutlineRight as Next, AiOutlineLogout as Logout, AiFillDatabase as IconFinalData } from 'react-icons/ai'
import { GrDocument as IconForm } from 'react-icons/gr'
import { BiSave as IconSave } from 'react-icons/bi'
import { BsCartCheckFill as IconCart } from 'react-icons/bs'
import { colors } from '../../assets/colors';
import { BsTrash as Trash, BsPencil as Edit } from 'react-icons/bs';
import LogoMutif from '../../assets/img/MutifLogo.png';
import LogoDamoza from '../../assets/img/DamozaLogo.png';
import LogoUpmore from '../../assets/img/UpMoreLogo.png';

type propsButton = {
    title?: String;
    isPrevious?: boolean;
    isLogin?: boolean;
    isLogout?: boolean;
    isDelete?: boolean;
    onClick?: () => void;
    children?: JSX.Element;
    isAddedCart?: boolean;
    isMutif?: boolean;
    isDamoza?: boolean;
    isUpmore?: boolean;
    borderColor?: string;
    isDisabled?: boolean;
}

export const MainButton: React.FC<propsButton> = ({title, isLogout, isMutif, isDamoza, isUpmore, borderColor}) => (
    <div className='containerMainButton' style={{borderLeft:`7px solid`,  backgroundColor: isLogout ? colors.decre : isMutif ? colors.main : isUpmore ? colors.entUpmore : isDamoza ? colors.entDamoza : colors.agree, borderColor }}>
        <Logout color={isLogout ? 'white' : "transparent"} size={'1.3rem'} />
        {isLogout ? null : (
            <div className='logo-entity' style={{display: title ? "none" : "block"}}>
                {
                    isMutif || isDamoza || isUpmore ? <img src={isMutif ? LogoMutif : isDamoza ? LogoDamoza : isUpmore ? LogoUpmore : LogoMutif} alt='Logo Entitas' /> : null 
                }
            </div>
        )}
        {isLogout || title ? <p>{title}</p> : null}
        <Next size={'1.3rem'} color={isLogout ? 'white' : isMutif ? "white" : isDamoza ? 'black' : 'white'} fontWeight={"800"} />
    </div>
)

export const NavButton: React.FC<propsButton> = ({title, isPrevious, onClick, isLogin, isDisabled}) => (
    <button className='py-2 w-full flex justify-center rounded box-border cursor-pointer' style={{backgroundColor: isPrevious ? colors.main : isLogin ? colors.main : colors.agree}} onClick={onClick} disabled={isDisabled}>
        <p className='text-xl text-white font-medium'>{title}</p>
    </button>
)

export const ModButton: React.FC<propsButton> = ({isDelete, onClick}) => (
    <div className='containerModButton' onClick={onClick} style={{backgroundColor: isDelete ? colors.decre : colors.edit}}>
        {isDelete ? <Trash color='white' /> : <Edit color='white' />}
    </div>
)

export const FormButton: React.FC<propsButton> = ({onClick, isAddedCart = false}) => {
    const styleIcon = {fontSize: "1rem", color: 'white', marginRight: "5px"}

    return(
        <div className="add-cart" onClick={isAddedCart ? undefined : onClick} style={{backgroundColor: isAddedCart ? colors.agree : null, fontSize: '1rem'} as React.CSSProperties}>
            {isAddedCart ? <IconCart style={styleIcon} /> : <IconSave style={styleIcon} />}
            {isAddedCart ? "Tersimpan" : "Simpan"}
        </div>
    )
}