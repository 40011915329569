import {
    ADD_CART,
    DELETE_CART,
    REMOVE_CART,
    UPDATE_CART,
    ADD_CLOTHES
} from './types';

export const Cart = (state, action) => {
    switch (action.type) {
      case ADD_CART:
        return state = action.payload;
      case UPDATE_CART:
        return state.map((prev) => {
          if (prev.id === action.payload.id) {
            return {
              ...prev,
              ...action.payload
            }
          } else {
            return prev;
          }
        });
      case REMOVE_CART:
        return state.filter(cart => cart.id !== action.payload);
        break;
      case DELETE_CART:
        return state = [];
        break;
    }
  }

export const Product = (state, action) => {
  switch (action.type) {
    case ADD_CLOTHES:
      return state = action.payload;
  }
}