import "./questStyles.scss";
import Select from 'react-select';
import currency from "currency.js";
import { colors } from "../../assets/colors";

export const QuestSize = ({
    category,
    value1, value2, value3, value4, value5, value6, value7, value8, value9, value10, value11, value12, value13, value14, value15, value16, valueOther,
    onChange1, onChange2, onChange3, onChange4, onChange5, onChange6, onChange7, onChange8, onChange9, onChange10, onChange11, onChange12, onChange13, onChange14, onChange15, onChange16, onChangeOther,
    onChangeColor, valueColor, optionsColor,
    stock,
    price1, price2, price3, price4, price5, price6, price7, price8, price9, price10, price11, price12, price13, price14, price15, price16, priceOther,
    entity,
    isVeil,
    discount = 0,
    price = 0,
    pressToVeil,
    isReadOnly,
    isForgetSelectColor,
}) => {

    function calculateDisc(price) {
        const disc = price * (discount / 100);
        return price - disc;
    }

    return (
        <div className="flex w-full justify-between items-center flex-col">
            <div className="flex w-full my-3 items-center justify-center rounded box-border">
                <div className="text-2xl font-semibold" style={{ fontWeight: '700', color: entity == "MUTIF" ? colors.main : 'black' }}>{currency(price, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })}</div>
            </div>
            <div className="flex w-full justify-between items-center rounded box-border bg-main p-2 px-4 text-white text-lg text-center font-semibold">
                <div className="text-center w-1/5">{category == 1 ? "Umur" : "Size"}</div>
                <div className="text-center w-1/4">Stok</div>
                <div className="w-1/3"></div>
                <div className="w-1/3">
                    <p className="price-label">Harga</p>
                    <p className="text-sm">(diskon {discount}%)</p>
                </div>
            </div>
            {Array.isArray(stock) || stock ? (
                <>
                    {
                        category == 5 ? (<>
                            <FormatQuest index={1} quest={stock[0]?.size?.size} stock={stock[0]} isReadOnly={isReadOnly} onChange={onChangeOther} value={valueOther} priceDisc={currency(calculateDisc(priceOther), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(priceOther, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                        </>) : (
                            <>
                                <FormatQuest index={1} quest={stock[0]?.size?.size} stock={stock[0]} isReadOnly={isReadOnly} onChange={onChange1} value={value1} priceDisc={currency(calculateDisc(price1), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price1, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                <FormatQuest index={2} quest={stock[1]?.size?.size} stock={stock[1]} isReadOnly={isReadOnly} onChange={onChange2} value={value2} priceDisc={currency(calculateDisc(price2), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price2, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                <FormatQuest index={3} quest={stock[2]?.size?.size} stock={stock[2]} isReadOnly={isReadOnly} onChange={onChange3} value={value3} priceDisc={currency(calculateDisc(price3), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price3, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                <FormatQuest index={4} quest={stock[3]?.size?.size} stock={stock[3]} isReadOnly={isReadOnly} onChange={onChange4} value={value4} priceDisc={currency(calculateDisc(price4), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price4, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                <FormatQuest index={5} quest={stock[4]?.size?.size} stock={stock[4]} isReadOnly={isReadOnly} onChange={onChange5} value={value5} priceDisc={currency(calculateDisc(price5), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price5, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                <FormatQuest index={6} quest={stock[5]?.size?.size} stock={stock[5]} isReadOnly={isReadOnly} onChange={onChange6} value={value6} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                {category == 4 ? (
                                    <>
                                        <FormatQuest index={1} quest={stock[6]?.size?.size} stock={stock[6]} isReadOnly={isReadOnly} onChange={onChange7} value={value7} priceDisc={currency(calculateDisc(price7), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price7, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={2} quest={stock[7]?.size?.size} stock={stock[7]} isReadOnly={isReadOnly} onChange={onChange8} value={value8} priceDisc={currency(calculateDisc(price8), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price8, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={3} quest={stock[8]?.size?.size} stock={stock[8]} isReadOnly={isReadOnly} onChange={onChange9} value={value9} priceDisc={currency(calculateDisc(price9), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price9, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={4} quest={stock[9]?.size?.size} stock={stock[9]} isReadOnly={isReadOnly} onChange={onChange10} value={value10} priceDisc={currency(calculateDisc(price10), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price10, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={5} quest={stock[10]?.size?.size} stock={stock[10]} isReadOnly={isReadOnly} onChange={onChange11} value={value11} priceDisc={currency(calculateDisc(price11), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price11, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={6} quest={stock[11]?.size?.size} stock={stock[11]} isReadOnly={isReadOnly} onChange={onChange12} value={value12} priceDisc={currency(calculateDisc(price12), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price12, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={7} quest={stock[12]?.size?.size} stock={stock[12]} isReadOnly={isReadOnly} onChange={onChange13} value={value13} priceDisc={currency(calculateDisc(price13), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price13, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={8} quest={stock[13]?.size?.size} stock={stock[13]} isReadOnly={isReadOnly} onChange={onChange14} value={value14} priceDisc={currency(calculateDisc(price14), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price14, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={9} quest={stock[14]?.size?.size} stock={stock[14]} isReadOnly={isReadOnly} onChange={onChange15} value={value15} priceDisc={currency(calculateDisc(price15), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price15, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                        <FormatQuest index={10} quest={stock[15]?.size?.size} stock={stock[15]} isReadOnly={isReadOnly} onChange={onChange16} value={value16} priceDisc={currency(calculateDisc(price16), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price16, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    </>
                                ) : null}
                            </>
                        )
                    }

                    {optionsColor ? (
                        <div className="quest-case mt-4">
                            <div className="label-quest w-1/5">warna</div>
                            <div className="input-quest w-4/5">
                                <div className="color-options">
                                    <Select
                                        className={`selector ${isForgetSelectColor ? 'error' : ""}`}
                                        styles={{ border: "1px solid gray", borderRadius: "5px", color: "black" }}
                                        onChange={onChangeColor}
                                        options={optionsColor}
                                        isSearchable={false}
                                        value={valueColor}
                                        placeholder={valueColor ? valueColor : "Pilih Warna"}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {isVeil ? (
                        <div className="quest-case">
                            <div className="veil-wrapper">
                                <div className="veil-btn" onClick={pressToVeil}>Pakai Hijab ?</div>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export const QuestSizeUpdate = ({
    category,
    value1, value2, value3, value4, value5, value6, value7, value8, value9, value10, value11, value12, value13, value14, value15, value16, valueOther,
    onChange1, onChange2, onChange3, onChange4, onChange5, onChange6, onChange7, onChange8, onChange9, onChange10, onChange11, onChange12, onChange13, onChange14, onChange15, onChange16, onChangeOther,
    onChangeColor, valueColor, optionsColor,
    stock,
    price1, price2, price3, price4, price5, price6,
    priceOther,
    entity,
    isVeil,
    discount = 0,
    price = 0,
    pressToVeil,
    isReadOnly,
    isForgetSelectColor
}) => {
    const quest = category == 1 ? ["S", "M", "L", "XL", "XXL", "XXXL"] : ["2", "4", "6", "8", "10", "12"]

    function calculateDisc(price) {
        const disc = price * (discount / 100);
        return price - disc;
    }

    return (
        <div className="flex w-full justify-between items-center flex-col">
            <div className="flex w-full my-3 items-center justify-center rounded box-border">
                <div className="text-2xl font-semibold" style={{ fontWeight: '700', color: entity == "MUTIF" ? colors.main : 'black' }}>{currency(price, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })}</div>
            </div>
            <div className="flex w-full justify-between items-center rounded box-border bg-main p-2 px-4 text-white text-lg text-center font-semibold">
                <div className="text-center w-1/5">{category == 1 ? "Umur" : "Size"}</div>
                <div className="text-center w-1/4">Stok</div>
                <div className="w-1/3"></div>
                <div className="w-1/3">
                    <p className="price-label">Harga</p>
                    <p className="text-sm">(diskon {discount}%)</p>
                </div>
            </div>
            {Array.isArray(stock) ? (
                <>
                    {category == 5 ? (
                        <>

                            <FormatQuest index={1} quest={stock[0]?.size?.size} stock={stock[0]} isReadOnly={isReadOnly} onChange={onChangeOther} value={valueOther} priceDisc={currency(calculateDisc(priceOther), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(priceOther, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                        </>
                    ) : (
                        <>
                            <FormatQuest index={1} quest={stock[0]?.size?.size} stock={stock[0]} isReadOnly={isReadOnly} onChange={onChange1} value={value1} priceDisc={currency(calculateDisc(price1), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price1, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            <FormatQuest index={2} quest={stock[1]?.size?.size} stock={stock[1]} isReadOnly={isReadOnly} onChange={onChange2} value={value2} priceDisc={currency(calculateDisc(price2), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price2, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            <FormatQuest index={3} quest={stock[2]?.size?.size} stock={stock[2]} isReadOnly={isReadOnly} onChange={onChange3} value={value3} priceDisc={currency(calculateDisc(price3), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price3, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            <FormatQuest index={4} quest={stock[3]?.size?.size} stock={stock[3]} isReadOnly={isReadOnly} onChange={onChange4} value={value4} priceDisc={currency(calculateDisc(price4), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price4, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            <FormatQuest index={5} quest={stock[4]?.size?.size} stock={stock[4]} isReadOnly={isReadOnly} onChange={onChange5} value={value5} priceDisc={currency(calculateDisc(price5), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price5, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            <FormatQuest index={6} quest={stock[5]?.size?.size} stock={stock[5]} isReadOnly={isReadOnly} onChange={onChange6} value={value6} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                            {category == 4 ? (
                                <>
                                    <FormatQuest index={1} quest={stock[6]?.size?.size} stock={stock[6]} isReadOnly={isReadOnly} onChange={onChange7} value={value7} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={2} quest={stock[7]?.size?.size} stock={stock[7]} isReadOnly={isReadOnly} onChange={onChange8} value={value8} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={3} quest={stock[8]?.size?.size} stock={stock[8]} isReadOnly={isReadOnly} onChange={onChange9} value={value9} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={4} quest={stock[9]?.size?.size} stock={stock[9]} isReadOnly={isReadOnly} onChange={onChange10} value={value10} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={5} quest={stock[10]?.size?.size} stock={stock[10]} isReadOnly={isReadOnly} onChange={onChange11} value={value11} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={6} quest={stock[11]?.size?.size} stock={stock[11]} isReadOnly={isReadOnly} onChange={onChange12} value={value12} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={7} quest={stock[12]?.size?.size} stock={stock[12]} isReadOnly={isReadOnly} onChange={onChange13} value={value13} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={8} quest={stock[13]?.size?.size} stock={stock[13]} isReadOnly={isReadOnly} onChange={onChange14} value={value14} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={9} quest={stock[14]?.size?.size} stock={stock[14]} isReadOnly={isReadOnly} onChange={onChange15} value={value15} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                    <FormatQuest index={10} quest={stock[15]?.size?.size} stock={stock[15]} isReadOnly={isReadOnly} onChange={onChange16} value={value16} priceDisc={currency(calculateDisc(price6), { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} priceOri={currency(price6, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })} />
                                </>
                            ) : null}
                        </>
                    )}
                </>
            ) : null}
            {optionsColor ? (
                <div className="quest-case">
                    <div className="label-quest">warna</div>
                    <div className="input-quest">
                        <div className="color-options">
                            <Select
                                className={`selector ${isForgetSelectColor ? 'error' : ""}`}
                                styles={{ border: "1px solid gray", borderRadius: "5px" }}
                                onChange={onChangeColor}
                                options={optionsColor}
                                isSearchable={false}
                                placeholder={valueColor}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            ) : null}
            {isVeil ? (
                <div className="quest-case">
                    <div className="veil-wrapper">
                        <div className="veil-btn" onClick={pressToVeil}>Pakai Hijab ?</div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

const FormatQuest = ({ quest, stock, isReadOnly, onChange, value, priceOri, priceDisc, index }) => {
    return (

        /* <div className="flex w-full my-3 items-center justify-center rounded box-border">
                <div className="text-2xl font-semibold" style={{ fontWeight: '700', color: entity == "MUTIF" ? colors.main : 'black' }}>{currency(price, { fromCents: true, precision: 0 }).format({ symbol: 'Rp' })}</div>
            </div>
            <div className="flex w-full justify-between items-center rounded box-border bg-main p-2 px-4 text-white text-lg text-center font-semibold">
                <div className="text-center w-1/5">{category == 1 ? "Umur" : "Size"}</div>
                <div className="text-center w-1/4">Stok</div>
                <div className="w-1/3"></div>
                <div className="w-1/3">
                    <p className="price-label">Harga</p>
                    <p className="text-sm">(diskon {discount}%)</p>
                </div>
            </div> */
        <div className={`quest-case border-b border-solid border-slate-400 py-2 ${index % 2 === 0 ? "bg-pink-200" : "bg-white"}`}>
            <div className="label-quest w-1/5" htmlFor={quest}>{quest}</div>
            <div className="stock-produk w-1/4" style={{ color: stock?.qty_buffer == 0 ? 'green' : 'black' }}>{stock?.qty_buffer == 0 ? "Tersedia" : stock?.qty_avaliable}</div>
            {/* <div className="stock-produk" style={{ color: stock?.qty_avaliable == 0 ? 'red' : 'black' }}>{stock?.qty_avaliable}</div> */}
            <input className="input-quest w-1/3 bg-transparent" readOnly={isReadOnly} min={0} type="number" name={quest} id={quest} placeholder={"0"} onChange={onChange} value={value == 0 || value == null ? 'hidden' : value} max={stock?.qty_avaliable != 0 ? stock?.qty_avaliable : 0} />
            <div className="price-produk w-1/3">
                <p className="price-ori text-sm">{priceOri}</p>
                <p className="font-semibold">{priceDisc}</p>
            </div>
        </div>
    )
}