import { colors } from "../../assets/colors";
import "./alert.scss";

const Alert = ({titleAlert, contentAlert, questAlert, txtYes, txtNo, pressYes, pressNo, isWarn, isAlert, alertReguler, varAlert}) => {
    return (
        <div className="alertForm">
            <p className="title" style={{backgroundColor: isWarn ? colors.edit : isAlert ? colors.decre : colors.main}} >{titleAlert.toUpperCase()}</p>
            <div className="content">
                <p className="contentAlert">{contentAlert}</p>
                <p className="questAlert">{questAlert}</p>
            </div>
            <p className="alertReguler">{alertReguler} <span>{varAlert}</span></p>
            <div className="handler">
                <button className="yes noSelect" onClick={pressYes}>{txtYes}</button>
                <button className="no noSelect" onClick={pressNo}>{txtNo}</button>
            </div>
        </div>
    )
};

export default Alert;