import { useEffect, useState } from "react";
import { Navbar } from "../../components";
import Search from "../../components/search/Search";
import { useNavigate, useLocation } from "react-router-dom";
import BoxItem from "../../components/boxCatalog/BoxCatalog";
import { NoStuff } from "../../components/NoStuff/NoStuff";
import { Trousers, Aks, Shirt, ShirtWmn, shirtKid, pantKid, pantKidWmn, shirtKidWmn } from '../../assets/img/IconProduct';
import { decrypt } from "../../components/tools/encrypt";
import { LIST_PRODUK } from "../../context/types";
import { toast } from "react-toastify";
// import { getData } from "../../assets/dummyDB";

const SearchScreen = () => {
    const [data, setData] = useState([]);
    const [dataConst, setDataConst] = useState([]);
    const [discount, setDiscount] = useState(0);

    // const ArrImg = [Produk1, Produk2, Produk3, Produk4, Produk5];
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getDataLocal()
    }, []);

    async function getDataLocal() {
        try {
            console.log("Search Screen");
            const dataSession = sessionStorage.getItem('list');
            const _data = await decrypt(dataSession, LIST_PRODUK);
            console.log(_data);
            
            if (_data) {
                console.log(_data);
                setData(_data.data);
                setDataConst(_data.data);
                setDiscount(_data.discount)
            } else {
                console.log(_data);
                toast.warn("Data tidak ada", {autoClose: 1500, closeOnClick: true})
            }
        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi', { autoClose: 900, closeOnClick: true })
        }
    }

    function searchItem(input) {
        try {
            if (input) {
                let inputUpCase = input.toUpperCase();
                let result = dataConst.filter(e => e.article_name.toUpperCase().includes(inputUpCase));
                setData(result);
            } else {
                setData(dataConst);
            }
        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi', { autoClose: 900 })
        }
    }

    return (
        <div className="w-screen box-border flex flex-col items-center bg-background font-sans">
            <div className="flex flex-col items-center w-full lg:w-1/3">
                <div className="h-11 w-full">
                    <Navbar nama_db="Cari Barang" pressBack={() => navigate(-1)} />
                </div>
                <div className="w-full flex justify-center my-5">
                    <Search placeholder={"Cari Barang...."} onChange={e => searchItem(e.target.value)} style={{ borderRadius: "5px" }} />
                </div>
                {data.length > 0 ? (
                    <div className="w-11/12 inline-flex flex-wrap box-content items-center gap-2">
                        {data.map((value, key) => {
                            // let idImg =  Math.floor(Math.random() * ((ArrImg.length - 1) - 0) + 0);
                            const lowPrice = value.type_id == 2 ? value.size_s : value.type_id == 4 ? value.size_27 : value.type_id == 5 ? value.other : value.size_2[0];
                            const highPrice = value.type_id == 2 ? value.size_xxl : value.type_id == 4 ? value.size_42 : value.type_id == 5 ? null : value.size_12[0];
                            const entityName = value.entity_name;
                            const img = value.type_id == 1 ? shirtKidWmn : value.type_id == 2 ? ShirtWmn : value.type_id == 3 ? pantKidWmn : value.type_id == 4 ? Trousers : Aks

                            return <BoxItem key={key} srcImg={img} nameProduct={value.article_name} lowPrice={lowPrice} highPrice={highPrice} pressToProduct={() => navigate(`/katalog/produk/all`, { state: { produk: dataConst, idProduk: value.id, discount } })} entityName={entityName} />
                        })}
                    </div>
                ) : <NoStuff />}
            </div>
        </div>
    )
}

export default SearchScreen;