import { useMemo, useReducer, useEffect } from "react";
import { Cart } from './context/reducer'
import { GlobalContext } from './context';
import { Routes, Route } from "react-router-dom";
import Keranjang from "./pages/cart/Cart";
import { Login, OTPScreen, Register } from './pages/auth'
import { SearchScreen, FormProductUpdate, FormAddress, FormAllClothes } from './pages/form'
import { Home, ListToko, Profile } from './pages/home';
import UpdatePhone from "./pages/form/updatePhone";
import { toast } from "react-toastify";
import { SplashScreen } from "./components/controlFlow/ControlFlow";
// import { ErrorScreen } from './components/controlFlow/ControlFlow'; 

function App() {
  const [cart, dispatchCart] = useReducer(Cart, [], () => {
    const localData = sessionStorage.getItem('cart');
    return Array.isArray(localData) ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])

  const provider = useMemo(() => (
    {cart, dispatchCart}
  ), [cart, dispatchCart]);

  try{
    return (
      <GlobalContext.Provider value={provider}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:nohp" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registrasi" element={<Register />} />
          <Route path="/search" element={<SearchScreen />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/list-toko" element={<ListToko />} />
          <Route path="/profile/update/:kindAddress" element={<FormAddress />} />
          <Route path="/profile/phone" element={<UpdatePhone />} />        
          <Route path="/otpConfirm" element={<OTPScreen />} />
          {/* <Route path="/choose-entity" element={<ChooseEntity />} /> */}
          {/* <Route path="/katalog/:entity" element={<DashboardItem />} />             */}
          {/* <Route path="/katalog/produk" element={<Form />} />         */}
          <Route path="/katalog/produk/all" element={<FormAllClothes />} />        
          {/* <Route path="/katalog/produk/veil/:idProduk" element={<FormProductHijab />} />         */}
          <Route path="/katalog/produk/update/:idProduk" element={<FormProductUpdate />} />        
          <Route path="/cart" element={<Keranjang />} />
          <Route path="/cart/:type" element={<Keranjang />} />
        </Routes>
      </GlobalContext.Provider>
    )
  } catch(e) {
    console.log("Error!");
    console.error(e)
    toast.error("Ada Error! Silahkan coba lagi");
    return(
      <SplashScreen />
    )
  }
}

export default App;