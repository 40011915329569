import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Navbar } from '../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import { ip } from '../../assets/api';
import Alert from '../../components/alert/Alert';
import { confirmAlert } from 'react-confirm-alert';
import { SplashScreen } from '../../components/controlFlow/ControlFlow';

const UpdatePhone = () => {
    const [phone, setPhone] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    // const location = useLocation();

    function validationFlowInput() {
        if (phone) {
            ModalConfirm()
        } else {
            toast.error("Anda belum mengisikan no HP", { autoClose: 1000 })
        }
    }

    function sendUpdatePhone() {
        console.log("Update Phone")
        setIsLoading(true);
        const phoneLocal = localStorage.getItem('phone')
        let formdata = new FormData();
        formdata.append("_method", "put");
        formdata.append("new_phone_number", phone);
        // console.log(`${a1}/MS/${a2}/${a3}/${a4}` + phone)
        // formdata.append("ms_code", location.state == 2 ? `${a1}/MS/${a2}/${a3}/${a4}` : a1);

        let requestOptions = {
            method: 'POST',
            headers: {
                Accept: "application/json"
            },
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${ip}/api/client/update-phone/${phoneLocal}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == "success" || result.status == "berhasil") {
                    // localStorage.setItem('phone', phone)
                    toast.warn(result.message || result.pesan, { autoClose: 900, onClose: () => { setIsLoading(false); navigate(-1) } })
                } else {
                    toast.error(result.message || result.pesan, { autoClose: 1000, onClose: () => setIsLoading(false) })
                    console.log(result);
                }
            })
            .catch(error => {
                console.error(error.message)
                toast.error(error.message, { autoClose: 1000, onClose: () => setIsLoading(false) })
                setIsLoading(false);
            });
    }


    // function limitInput(e) {
    //     if (e.target.value.length > e.target.maxLength)
    //     e.target.value = e.target.value.slice(0,e.target.maxLength);
    // }

    const ModalConfirm = () => {
        try {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return <Alert
                        titleAlert={"Peringatan!"}
                        isWarn
                        alertReguler={`Apakah anda sudah yakin dengan nomor ${phone} ini?`}
                        txtNo={"Tidak Yakin"}
                        txtYes={"Yakin"}
                        pressNo={() => {
                            onClose();
                            setIsLoading(false);
                        }}
                        pressYes={() => {
                            sendUpdatePhone();
                            onClose();
                        }}
                    />
                }
            })
        } catch (e) {
            console.log(`Error Confirm ${e.message}`);
            toast("Ada error, silahkan menghubungi Admin!");
        }
    }

    return isLoading ? <SplashScreen /> : (
        <div className="w-screen pb-3 flex flex-col items-center box-border font-sans">
            <div className='w-full lg:w-1/3'>
                <div className="h-11 w-full">
                    <Navbar pressBack={() => navigate(-1, { replace: true })} nama_db="Edit Nomor HP" />
                </div>
                <ToastContainer />
                <div className="bg-white m-4 p-2 rounded-md shadow-md">
                    <div className="box-form">
                        <div className="parent-form parent-phone">
                            {/* <div className="img-form">
                                <img src={DummyAvatar} />
                            </div> */}
                            <InputGeneral label={"Nomor HP baru"} type={"tel"} placeholder={"0895xxxxxxxx"} onChange={e => setPhone(e.target.value)} />
                            {/* <InputGeneral label={"Kode MS"} placeholder={"MS"} onChange={e => setMSCode(e.target.value)} /> */}
                            <div className="w-full flex items-center justify-center">
                                <button className="bg-main active:bg-btnDisable px-9 rounded-md shadow text-white text-lg font-semibold mb-2" onClick={(e) => {
                                    e.preventDefault();
                                    validationFlowInput();
                                }}>
                                    <span>edit</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const InputGeneral = ({ label, placeholder, type, onChange, value, isEditable, pressNavigate }) => {
    return (
        <div className="flex flex-col my-1 w-full">
            <label className='px-1 text-xl font-semibold'>{label}</label>
            <input className='my-3 py-1 px-1 border border-solid border-slate-500 rounded-md' placeholder={placeholder} type={type} onChange={onChange} value={value} readOnly={isEditable} />
        </div>
    )
}

export default UpdatePhone;
