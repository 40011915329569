import Drawer from "./Drawer";
import "./style/drawerDetil.scss";
import { AiOutlineClose as Close, AiOutlineArrowRight as ArrowRight } from "react-icons/ai";
import { colors } from "../../assets/colors";

export const DrawerDetil = ({ data, brand, comboColor, isVeil, isAllProduct }) => {
  const txtColor = data.entity_name == "MUTIF" || isAllProduct ? colors.main : 'black'
  const ListDetil = ({label, content}) => {
    return (
      <li className="flex w-full justify-between box-border text-base">
        <div className="w-1/3 mr-1 font-bold">{label}</div>
        <div className="w-3/4">: {content}</div>
      </li>
    )
  }

  return (
    <div className="w-full flex flex-wrap items-center justify-center flex-col noSelect">
      <div className="flex mb-3 text-center">
        <h2 className="text-xl font-bold" style={{ color: txtColor }}>{brand} {isVeil ? "(HIJAB)" : null}</h2>
        <p className="text-lg font-medium" style={{ color: txtColor }}>{comboColor}</p>
      </div>
      <div className="flex items-center justify-center w-full bg-stone-200 border border-solid border-stone-500 rounded-md shadow p-3">
        <ul className="w-full flex flex-col justify-center">
          <ListDetil label={"Nama Produk"} content={`${data.article_name} ${isVeil ? "HIJAB" : ""}`} />
          <ListDetil label={"Warna Utama"} content={data.color} />
          <ListDetil label={"Bahan"} content={data.material} />
          <ListDetil label={"Fitur Spesial"} content={data.special_feature} />
          <ListDetil label={"Deskripsi"} content={data.description} />
        </ul>
      </div>
    </div>
  )
}

export const DrawerPage = ({ isOpenPage, pressCloseDrawer, children, entity, isAllProduct }) => {
  return (
    <Drawer
      isOpen={isOpenPage}
      onClose={pressCloseDrawer}
      position="right"
    >
      <div className="drawer-page">
        <div className="drawer-header" style={{ color: entity == "MUTIF" || isAllProduct ? colors.main : 'black' }}>
          <button type="button" onClick={pressCloseDrawer}>
            <ArrowRight color={entity == "MUTIF" || isAllProduct ? colors.main : 'black'} />
          </button>
          <h2>Daftar Product {entity}</h2>
        </div>
        {children}
      </div>
    </Drawer>
  )
}

/* onCLick={} */