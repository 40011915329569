import { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import { ip } from '../../assets/api';
import { IoMdCart as IconCart } from 'react-icons/io';
import { BiHistory as IconHistory } from 'react-icons/bi'
import { FaWpforms as IconForm } from 'react-icons/fa';
import { VscAccount as IconUsr } from 'react-icons/vsc'
import { RiLogoutBoxLine as IconLogout } from 'react-icons/ri';
import { AiOutlineStop as IconStop } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SplashScreen } from '../../components/controlFlow/ControlFlow';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClockPO from '../../components/clock/Clock';
import { GlobalContext } from '../../context';
import { ADD_CART, DELETE_CART, LIST_PRODUK, USER, USER_PRODUK } from '../../context/types';
import Search from '../../components/search/Search';
import ExitApp from '../../components/exitapp/ExitApp';
import { encrypt, decrypt } from '../../components/tools/encrypt';
// import { MainButton } from '../../components/button/Button';
// import ExitApp from '../../components/exitapp/ExitApp';
// import Clock from 'react-clock';

const Home = () => {
    // const { cart, dispatchCart } = useContext(GlobalContext);
    const { dispatchCart } = useContext(GlobalContext);
    const [data, setData] = useState();
    const [dataClothes, setDataClothes] = useState([]);
    const [dataFinal, setDataFinal] = useState([0]);
    const [discount, setDiscount] = useState();
    const [isFinalData, setIsFinalData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHaveAccess, setIsHaveAccess] = useState();
    const [isEndSession, setIsEndSession] = useState(false);
    const [isHaveStore, setIsHaveStore] = useState(true);
    const [isHaveToSend, setIsHaveToSend] = useState(false);
    // const [isClose, setIsClose] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const history = createBrowserHistory();

    useEffect(() => {
        firstRender();
        // setData(DataProfile.account)
    }, []);

    async function firstRender() {
        try {
            setIsLoading(true);
            const haveAccessLocale = localStorage.getItem('isHaveAccess');
            const localDataHistory = localStorage.getItem('history');
            const parseHistory = await JSON.parse(localDataHistory);

            if (parseHistory) {
                setDataFinal(parseHistory);
            }
            setIsHaveAccess(haveAccessLocale);
            getPhoneParams();
        } catch (e) {
            console.log(e.message);
        }
    }

    function checkIsHaveAccess() {
        if (isHaveAccess) {
            navigate("/login", { replace: true })
        } else {
            navigate("/registrasi", { replace: true })
        }
    }

    function getPhoneParams() {
        const phoneParams = params.nohp;
        if (phoneParams) {
            // console.log("Get Phone Params")
            localStorage.setItem('phone', phoneParams)
            localStorage.setItem('isHaveAccess', true)
            getDataUser(phoneParams);
        } else {
            getPhoneLocal();
        }
    }

    function getPhoneLocal() {
        // console.log('Get Phone From Local')
        const getPhone = localStorage.getItem('phone');
        if (getPhone == null) {
            checkIsHaveAccess()
            // toast.warn('Login terlebih dahulu', {autoClose: 2000})
        } else {
            getDataUser(getPhone);
        }
    }

    function getDataUser(phone) {

        const requestOptions = {
            method: "GET",
            redirect: 'follow',
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };

        fetch(`${ip}/api/client/login/${phone}`, requestOptions)
            .then(res => res.json())
            .then(async response => {
                // console.log(response)
                if (response.status == "failed") {
                    toast.error(response.message || response.pesan, {
                        position: "top-right",
                        autoClose: 900,
                        closeOnClick: true,
                        onClose: () => {
                            localStorage.removeItem('phone');
                            checkIsHaveAccess();
                        }
                    })
                } else if (response.status == "waiting") {
                    toast.warn(`🙌 ${response.message}`, {
                        autoClose: 1000,
                        closeOnClick: true,
                        onClose: () => logOut()
                    })
                } else if (response.status == "closed") {
                    toast.warn(response.message, {
                        autoClose: 900,
                        closeOnClick: true,
                        onClose: () => { setIsEndSession(true); setIsLoading(false); }
                    })
                } else {
                    const finalData = response?.final_data;
                    const dataUser = response?.user;
                    const routeFinalData = finalData.length > 0 ? true : false;
                    const isHaveGetProduct = sessionStorage.getItem('list');
                    const encryptUser = encrypt(response.user, USER);

                    localStorage.setItem('isHaveAccess', true)
                    sessionStorage.setItem("user", encryptUser);
                    setData(response.user);
                    setIsFinalData(routeFinalData);

                    if (Array.isArray(finalData) && finalData.length > 0) {
                        setDataFinal(finalData);
                        sessionStorage.setItem("isFinalData", true);
                    } else {
                        sessionStorage.setItem("isFinalData", false);
                    }

                    // Validasi Toko
                    if (Array.isArray(dataUser.mutif_store_master) && dataUser.mutif_store_master.length > 0) {
                        if (!routeFinalData && isHaveGetProduct) {
                            const parseProduct = await decrypt(isHaveGetProduct, LIST_PRODUK);

                            setDataClothes(parseProduct?.data || []);
                            setDiscount(parseProduct?.discount);
                            setIsLoading(false);
                        } else {
                            getDataClothes(dataUser.phone);
                        }
                    } else {
                        setIsHaveStore(false);
                        setIsLoading(false);
                        // toast.warn('Akun ini tidak memiliki toko!', {autoClose: 1000, closeOnClick: true});
                    }
                    // console.log("Cart")
                    // console.log(response.cart);
                    if (response.cart) {
                        dispatchCart({ type: ADD_CART, payload: response.cart });
                        setIsHaveToSend(true);
                    } else {
                        dispatchCart({type: DELETE_CART});
                        setIsHaveToSend(false);
                    }

                    toast.success(`🙌 ${response.message}`, {
                        autoClose: 1000,
                        closeOnClick: true
                    })
                }
            })
            .catch(err => {
                console.error(err)
                toast.warn(err.message, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    onClose: () => {
                        localStorage.removeItem('phone');
                        checkIsHaveAccess();
                    }
                })
            })
    }

    function getDataClothes(phone) {
        console.log("Get Data Clothes")
        const requestOptions = {
            method: "GET",
            redirect: 'follow',
        };
        fetch(`${ip}/api/pre-order/${phone}`, requestOptions)
            .then(async res => {
                const response = await res.json();
                // console.log(response);
                if (res.status == 200) {
                    // console.log("response get data clotehs api");
                    // console.log(response);

                    const listProdukEncrypt = encrypt({ discount: response.discount, data: response.data }, LIST_PRODUK);
                    sessionStorage.setItem('list', listProdukEncrypt);

                    setDiscount(response.discount);
                    setDataClothes(response.data);
                    setIsLoading(false);
                }
                if (res.status == 400) {
                    console.log(response);
                    toast(response.message, {
                        position: "top-right",
                        autoClose: 1500,
                        closeOnClick: true,
                        type: 'warning',
                        onClose: () => setIsLoading(false)
                    })
                }
                if (res.status == 500) {
                    console.log(response);
                    toast(res.statusText, {
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        type: 'error',
                        onClose: () => setIsLoading(false)
                    })
                }

                if (res.status == 300) {
                    // console.log(response)
                    setDataClothes(response.data);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.error(err)
                toast(err.message, {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    type: 'error',
                })
                setIsLoading(false);
            })
    }

    function logOut() {
        toast.success('Berhasil LogOut', {
            position: 'top-right',
            autoClose: 900,
            pauseOnHover: true,
            closeOnClick: true,
            onClose: () => {
                localStorage.removeItem('phone');
                localStorage.removeItem('history');
                localStorage.removeItem('_grecaptcha');
                sessionStorage.removeItem('list');
                sessionStorage.removeItem('cart');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('data_user');
                sessionStorage.removeItem('isFinalData');
                navigate('/login', { replace: true });
            }
        })
    }

    const BoxProfile = ({ nameUser, roleUser, qtDamoza, qtMutif, qtUpmore, isEndSession }) => {
        return (
            <div className="bg-white shadow-md p-3 w-4/5 rounded-lg" >
                <div className="flex justify-between items-center py-4">
                    <div className="w-2/5 flex justify-center">
                        <ClockPO />
                    </div>
                    <div className="w-3/5 flex flex-col items-center text-center">
                        <h3 className="text-2xl font-bold">{nameUser}</h3>
                        <p className="text-xl font-semibold mt-1">{roleUser}</p>
                    </div>
                </div>
                {/* {isEndSession ? (
                    <div className="summary-wrapper">
                        <div className="name-summary">
                            <p>Akumulasi Input</p>
                        </div>
                        <div className="summary">
                            <div className="entity-container">
                                <h3 className="entity-name">Damoza</h3>
                                <p className="total-produk">{qtDamoza}</p>
                            </div>
                            <div className="entity-container middle">
                                <h3 className="entity-name">Mutif</h3>
                                <p className="total-produk">{qtMutif}</p>
                            </div>
                            <div className="entity-container">
                                <h3 className="entity-name">UpMore</h3>
                                <p className="total-produk">{qtUpmore}</p>
                            </div>
                        </div>
                    </div>
                ) : null} */}
            </div>
        )
    }

    return (
        isLoading ? <SplashScreen /> : isEndSession ? <ExitApp onPress={() => logOut()} /> : (
            <div className='w-screen flex justify-center font-sans'>
                <div className="w-full lg:w-1/3">
                    <ToastContainer />
                    <div className="h-11 w-full">
                        <Navbar isDashboard={true} isNotHaveAccess={!isHaveStore || isFinalData} />
                    </div>
                    {dataClothes.length > 0 && isHaveToSend ? (
                        <div className="search-session bg-gray-500 ">
                            <Search placeholder={"Klik untuk cari barang"} onStartInput={() => navigate("/search", { state: { data: dataClothes } })} />
                        </div>
                    ) : null}

                    <div className="flex flex-col items-center w-full h-full box-border pt-8 pb-2">
                        <BoxProfile
                            nameUser={data?.name}
                            roleUser={data?.group_code}
                        />
                        <div className="flex flex-col items-center justify-center w-4/5">
                            <div className='w-full flex flex-wrap my-5 justify-between'>
                                {
                                    !isHaveStore ? <BoxMenu onClick={() => toast.warn("Akun ini tidak memiliki toko!", { autoClose: 1300, closeOnClick: true })} iconChild={<IconStop size={33} />} titleMenu={'Fitur Terkunci'} bgColour={"#0FA958"} /> :
                                        isFinalData ? (
                                            <BoxMenu onClick={() => navigate("/cart", { state: { dataFinal, isFinalData: true, isHaveToSend: false } })} iconChild={<IconCart size={33} />} titleMenu={"Final Data"} bgColour={"#0FA958"} />
                                        ) : dataClothes.length > 0 && isHaveToSend ? (
                                            <BoxMenu onClick={() => navigate('/katalog/produk/all', { state: { produk: dataClothes, discount: discount } })} iconChild={<IconForm size={33} />} titleMenu={'Mulai Isi Form'} bgColour={"#0FA958"} />
                                        ) : (
                                            <BoxMenu onClick={() => navigate("/cart/history")} iconChild={<IconHistory size={40} />} titleMenu={'Riwayat'} bgColour={"#0FA958"} />
                                        )}
                                <BoxMenu onClick={() => navigate("/profile", { state: data })} iconChild={<IconUsr size={33} fontWeight={20} />} titleMenu={"Profil"} bgColour={"#EAAE13"} />
                            </div>
                            <div className='bg-denied w-full box-border p-2 flex justify-center items-center text-white rounded-lg shadow-sm noSelect' onClick={() => logOut()} >
                                <IconLogout size={30} />
                                <p className="text-xl font-semibold ml-1">logout</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default Home;

const BoxMenu = ({ iconChild, titleMenu, bgColour, onClick }) => {
    return (
        <div className="flex flex-col justify-center items-center box-border rounded-md text-white shadow-sm noSelect active:opacity-30" style={{ backgroundColor: bgColour, height: "15vh", width: "47%" }} onClick={onClick} >
            <div>
                {iconChild}
            </div>
            <div className="text-md font-semibold mt-2">
                {titleMenu}
            </div>
        </div>
    )
}
