import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ip, ip_dev } from "../../assets/api";
import { Navbar } from "../../components"
import { SplashScreen } from "../../components/controlFlow/ControlFlow";
import EmptyStoreImg from '../../assets/img/EmptyStore.png';
import "./style/listToko.scss";
import { toast, ToastContainer } from "react-toastify";
// import { AlertBox } from "../cart/Cart";
// import useLongPress from "../../components/hooks/useLongPress";
// import Alert from "../../components/alert/Alert";
// import { confirmAlert } from "react-confirm-alert";

const ListToko = () => {
    const [data, setData] = useState();
    const [role, setRole] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getDataState();
    }, [])

    function getDataState(){
        setIsLoading(true)
        const phone = localStorage.getItem('phone');
        const requestOptions = {
            method: "GET",
            redirect: 'follow',
        };

        fetch(`${ip}/api/client/login/${phone}`, requestOptions)
            .then(res => res.json())
            .then(response => {
                // console.log(response)
                if(response.status == "failed"){
                    toast.error(response.message, {autoClose: 900})
                    setIsLoading(false)
                    // setIsClose(true)
                } else {
                    console.log(response);
                    setRole(response.user.partner_group_id);
                    setData(response.user.mutif_store_master);
                    setIsLoading(false)
                    // sessionStorage.setItem("user", JSON.stringify(response.user));
                } 
            })
            .catch(err => {
                console.error(err)
                setIsLoading(false)
                toast.error("Ada Error! Silahkan coba lagi", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    onClose: () => {
                        localStorage.removeItem('phone');
                        navigate('/login', {replace: true});
                    }
                })
            })
    }

    /* function deleteStore(id) {
        const phone = localStorage.getItem('phone');
        var formdata = new FormData();
        formdata.append("_method", "delete");
    
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
    
        fetch(`${ip}/api/mutif-store/${phone}/store/${id}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status == "success") {
                getDataState();
              toast.success(result.message, {autoClose: 900})
            } else {
              toast.error(result.message, {autoClose: 1500})
            }
          })
          .catch(error => {
            console.log('error', error)
            toast.error(error.message, {autoClose: 1500})
          });
      } */

/*       function alertConfirmDelete({name, id}) {
        confirmAlert({
          customUI: ({onClose}) => {
            return <Alert 
              titleAlert={"Peringatan"}
              isAlert
              alertReguler={`Apakah anda yakin ingin menghapus toko`}
              varAlert={name}
              txtNo={"tidak"}
              txtYes={"ya"}
              pressNo={() => {
                onClose()
                setIsLoading(false);
              }}
              pressYes={() => {
                deleteStore(id)
                onClose();
              }}
            />
          }
        })
      } */
      

    return !data || isLoading ? <SplashScreen /> : (
        <div className="listTokoContainer">
            <div className="navbar">
                <Navbar nama_db="List Toko" pressBack={() => navigate(-1, {replace: true})} />
            </div>
            <ToastContainer />
            <div className="storeWrapper">
                {/* {Array.isArray(data) && data.length > 0 ? <AlertBox title={"Pengumuman!"} content1={"Untuk melakukkan perubahan data toko, silahkan untuk menghubungi admin kami!"} /> : null} */}
                <div className="listItemStore">
                        {Array.isArray(data) && data?.length > 0 ? data?.map((value, key) => {
                            return (
                                <BoxList key={key} 
                                    name={value.mutif_store_name}
                                    openDate={value.open_date}
                                    msCode={value.mutif_store_code}
                                    address={value.mutif_store_address[0] ? `${value.mutif_store_address[0].address}` : null}
                                    telp={value.mutif_store_address[0] ? value.mutif_store_address[0].phone_1 : null}
                                    detil={value.mutif_store_address[0] ? value.mutif_store_address[0].comment : null}
                                    status={value.status}
                                    pressEdit={() => navigate('/profile/update/toko', {state: {store: value, isEditStore: true}})}
                                />
                            )
                        }) : (
                            <div className="emptyStoreContainer">
                                <img src={EmptyStoreImg} />
                                <div className="contentTxt">
                                    <h2>KOSONG</h2>
                                    <p className="instruction">Silahkan melakukan pendataan ulang melalui tombol di bawah ini</p>
                                </div>
                            </div>
                        )}
                </div>
                <div className="buttonCreateStore">
                    <ButtonRegistStore onClick={() => navigate('/profile/update/toko', {state: {role: role}})} />
                </div>
            </div>
        </div>
    );
}

export default ListToko;

const BoxList = ({name, openDate, msCode, address, telp, detil, status, pressEdit, onLongPress, onPress}) => {
    const longPress = onLongPress;

    const click = onPress;

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    // const longPressEvent = useLongPress(longPress, click, defaultOptions);

    return(
        <div className="boxItem">
            <div className="title">
                <p>{name}</p>
                <div className="btnEdit noSelect" onClick={pressEdit}>edit</div>
            </div>
            <div className="contentItemStore">
                <ItemList label={"Tanggal Buka"} value={openDate} />
                <ItemList label={"Kode Toko"} value={msCode} />
                <ItemList label={"Alamat"} value={address} />
                <ItemList label={"Telepon"} value={telp} />
                <ItemList label={"Detail"} value={detil} />
                <ItemList label={"Status"} value={status} />
            </div>
        </div>
    )
}

const ItemList = ({label, value}) => {
    return(
        <div className="item">
            <div className="label">{label}</div>
            <div className="value">{value}</div>
        </div>
    )
}

const ButtonRegistStore = ({onClick}) => {
    return(
        <div className="btnRegistStore noSelect"  onClick={onClick}>
            <div className="txtBtn">Daftar Toko</div>
        </div>
    )
}