import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import logo from '../../assets/img/mutifweb.png';
import { BsTelephone } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ip } from '../../assets/api';
import { BeatLoader } from 'react-spinners'
import { css } from "@emotion/react";
// import './style/login.scss';
// import { SplashScreen } from '../../components/controlFlow/ControlFlow';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export {override, BeatLoader}

const Login = () => {
    const [phone, setPhone] = useState("0");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    function sendData(e) {
        e.preventDefault();
        setIsLoading(true);
        localStorage.setItem('phone', `${phone}`);
        const requestOptions = {
            method: "GET",
            redirect: 'follow',
        };

        fetch(`${ip}/api/client/login/${phone}`, requestOptions)

            .then(async res => {
                // console.log(res);
                const response = await res.json();
                // console.log(response)
                if (res.status == 200) {
                    sessionStorage.setItem('user', JSON.stringify(response.user));
                    if (response.status == "waiting") {
                        toast(response.message, {
                            position: "top-right",
                            autoClose: 900,
                            closeOnClick: true,
                            type: 'warning',
                        })
                    } else if (response.status == "failed") {
                        toast(response.message || response.pesan, {
                            position: "top-right",
                            autoClose: 900,
                            closeOnClick: true,
                            type: 'warning',
                        })
                    } else {
                        toast("Berhasil Login", {
                            position: "top-right",
                            autoClose: 900,
                            closeOnClick: true,
                            type: 'success',
                            onClose: () => navigate('/', { replace: true })
                        })
                    }
                } /* else if(res.status == 400) {
                    toast(response.message, {
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        type: 'warning',
                    });
                }*/ else {
                    toast(response.message, {
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        type: 'error',
                    });
                }

                if (res.status == 500) {
                    toast(res.statusText, {
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                        type: 'error',
                    })
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                toast("Ada Error! Silahkan coba lagi", {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    type: 'error',
                })
                setIsLoading(false);
            })
    }

    return (
        <div className="w-screen h-screen flex items-center justify-center font-sans">
            <div className='flex items-center justify-center w-full h-full lg:w-1/3 md:w-1/2'>
                <ToastContainer />
                <div className="600 w-full flex flex-col items-center justify-center">
                    <img className=' w-2/3 border border-black rounded' src={logo} alt="Gambar Logo" />
                    <form className='items-center w-4/5'>
                        <div className="flex mx-auto box-border border-2-so border border-solid rounded-lg py-2 items-center justify-center my-5">
                            <BsTelephone className='w-1/5' size={"1.5rem"} />
                            <input className='w-4/5 text-xl active:outline-none focus:outline-none' type={"tel"} placeholder="Nomor HP" autoComplete='tel' required onChange={e => setPhone(e.target.value)} pattern={"^(\+62|62|0)8[1-9][0-9]{6,9}$"} />
                        </div>
                        <div className='flex items-center justify-center mb-7'>
                            {isLoading ? (
                                <div className="button bg-main py-2 px-10 text-center text-lg rounded-lg text-background font-bold shadow-current shadow-sm active:bg-btnHover, active:shadow">
                                    <BeatLoader loading css={override} color={'white'} />
                                </div>
                            ) : (
                                <button className="button bg-main py-2 px-10 text-center text-lg rounded-lg text-background font-bold shadow-current shadow-sm active:bg-btnHover, active:shadow" onClick={sendData} disabled={isLoading}>
                                    Login
                                </button>
                            )}
                        </div>
                    </form>

                    <div className="w-full flex flex-col items-center">
                        <p className='text-lg font-normal'>Belum punya akun? </p>
                        <Link to="/registrasi" className='text-lg font-semibold text-blue-700 active:text-blue-300'>
                            Yuk daftar dulu!
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;