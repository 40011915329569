import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Navbar, Item } from '../../components';
import { GlobalContext } from '../../context';
import { ADD_CART, REMOVE_CART } from '../../context/types';
import { ip } from '../../assets/api';
import { toast, ToastContainer } from 'react-toastify';
import { SplashScreen } from '../../components/controlFlow/ControlFlow';
import currency from 'currency.js';
import { FiAlertOctagon as IconAlert, FiRefreshCw as IconRefresh } from 'react-icons/fi';
import { NoStuffCart } from '../../components/NoStuff/NoStuff';
import Search from '../../components/search/Search';
import { confirmAlert } from 'react-confirm-alert';
import Alert from '../../components/alert/Alert';
import moment from 'moment';

const Cart = () => {
    const { cart, dispatchCart } = useContext(GlobalContext);
    const [phone, setPhone] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [totalUpmore, setTotalUpmore] = useState(0);
    const [totalDamoza, setTotalDamoza] = useState(0);
    const [totalMutif, setTotalMutif] = useState(0);
    const [totalPrice, setTotalPrice] = useState();
    const [data, setData] = useState([]);
    const [dataConst, setDataConst] = useState([]);
    const [isFinalData, setIsFinalData] = useState(false);
    const [isHaveToSend, setIsHaveToSend] = useState(false);
    const [isHistory, setIsHistory] = useState(false);
    const [datePicker, setDatePicker] = useState();

    let navigate = useNavigate();
    const location = useLocation();
    const params = useParams();


    useEffect(() => {
        const getPhoneLocal = localStorage.getItem('phone');
        const getIsFinalData = sessionStorage.getItem('isFinalData');

        console.log(params);
        if (params?.type == "history") {
            setIsHistory(true);
            getHistory(getPhoneLocal);
        } else {
            setIsHistory(false);
            getCartAPI();
        }

        if (getPhoneLocal) {
            setPhone(getPhoneLocal);
        }


    }, []);

    useEffect(() => {
        try {
            const paramDataHistory = location.state?.dataFinal;
            const isFinalData = location.state?.isFinalData;
            const isHaveToSend = location.state?.isHaveToSend;
            // console.log("Param Data History");
            // console.log(paramDataHistory);
            setIsFinalData(isFinalData);
            setIsHaveToSend(isHaveToSend);

            if (paramDataHistory) {
                setData(paramDataHistory);
                setDataConst(paramDataHistory);
            } else {
                setData(cart);
                setDataConst(cart);
            }
            calculateProduct();
        } catch (e) {
            console.error(e);
        }
    }, [cart])

    useEffect(() => {
        calculateProduct();
    }, [data, cart])

    function getHistory(phone) {
        setIsLoading(true);
        console.log("Get Data History");
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        console.log(requestOptions);

        fetch(`${ip}/api/pre-order/${phone}/history`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.status === "success") {
                    setData(result.data);
                    setDataConst(result.data);
                } else {
                    toast.warn(result.message, { autoClose: 1500, closeOnClick: true });
                    console.log(result);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log('error', error)
                setIsLoading(false);
            });
    }

    function sendData() {
        setIsLoading(true);
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        fetch(`${ip}/api/pre-order/store-all/${phone}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                if (result.status == 'success') {
                    setIsFinalData(false);
                    setIsHaveToSend(true);
                    sessionStorage.setItem('isFinalData', false);
                    localStorage.setItem("history", JSON.stringify(cart));
                    toast.success(result.message, { autoClose: 900, onClose: () => { setIsLoading(false) } })
                } else {
                    toast.error(result.message, { autoClose: 900, onClose: () => setIsLoading(false) });
                }
            })
            .catch(error => { console.error('error', error); toast.error(error.message, { autoClose: 900, onClose: () => setIsLoading(false) }); });
    }

    function deleteStuff(id) {
        setIsLoading(true);
        let formdata = new FormData();
        formdata.append("_method", "delete")

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${ip}/api/pre-order/${phone}/clothes/${id}`, requestOptions)
            .then(res => res.json())
            .then(response => {
                // console.log(response)
                if (response.status == "success") {
                    dispatchCart({ type: REMOVE_CART, payload: id })
                    calculateProduct();
                    toast.success(response.message, { autoClose: 900, onClose: () => { setIsLoading(false) } })
                } else {
                    toast.error(response.message, { autoClose: 1500, onClose: () => setIsLoading(false) })
                }
            })
            .catch(error => { console.log('error', error); toast.error("Ada Error! Silahkan coba lagi", { autoClose: 900, onClose: () => setIsLoading(false) }); })
    }

    function getCartAPI() {
        setIsLoading(true);
        const getPhoneLocal = localStorage.getItem('phone');
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ip}/api/pre-order/cart/${getPhoneLocal}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log("getCartapi")
                // console.log(result)
                dispatchCart({ type: ADD_CART, payload: result.cart })
                setData(result.cart);
                setDataConst(result.cart);
                calculateProduct();
            })
            .catch(error => {
                console.error(error);
                toast.error("Ada Error! Silahkan coba lagi", { autoClose: 900 })
                setIsLoading(false);
            });
    }

    async function calculateProduct() {
        const _data = cart.length > 0 ? cart : data;
        try {
            let totalStuffDamoza = 0;
            let totalStuffMutif = 0;
            let totalStuffUpmore = 0;
            let totalPrice = 0;

            if (_data?.length > 0) {
                // console.log("Calculate Product");
                // console.log(_data);
                const entDamoza = _data.filter(e => {
                    const entity = e.clothes?.entity_name || e?.entity_name
                    return entity == "DAMOZA"
                });
                const entUpmore = _data.filter(e => {
                    const entity = e.clothes?.entity_name || e?.entity_name
                    return entity == "UPMORE"
                });
                const entMutif = _data.filter(e => {
                    const entity = e.clothes?.entity_name || e?.entity_name
                    return entity == "MUTIF"
                });


                if (entDamoza.length > 0) {
                    // console.log("Lopping Damoza");
                    entDamoza.map((value) => {
                        totalStuffDamoza += (parseInt(value?.size_2) + parseInt(value?.size_4) + parseInt(value?.size_6) + parseInt(value?.size_8) + parseInt(value?.size_10) + parseInt(value?.size_12) + parseInt(value?.size_s) + parseInt(value?.size_m) + parseInt(value?.size_l) + parseInt(value?.size_xl) + parseInt(value?.size_xxl) + parseInt(value?.size_xxxl) + parseInt(value?.other) + parseInt(value?.size_27) + parseInt(value?.size_28) + parseInt(value?.size_29) + parseInt(value?.size_30) + parseInt(value?.size_31) + parseInt(value?.size_32) + parseInt(value?.size_33) + parseInt(value?.size_34) + parseInt(value?.size_35) + parseInt(value?.size_36) + parseInt(value?.size_37) + parseInt(value?.size_38) + parseInt(value?.size_39) + parseInt(value?.size_40) + parseInt(value?.size_41) + parseInt(value?.size_42));
                        totalPrice += parseInt(value?.total);
                    })
                    // console.log(totalStuffDamoza)
                }

                if (entUpmore.length > 0) {
                    // console.log("Looping Upmore");
                    entUpmore.map((value) => {
                        totalStuffUpmore += (parseInt(value?.size_2) + parseInt(value?.size_4) + parseInt(value?.size_6) + parseInt(value?.size_8) + parseInt(value?.size_10) + parseInt(value?.size_12) + parseInt(value?.size_s) + parseInt(value?.size_m) + parseInt(value?.size_l) + parseInt(value?.size_xl) + parseInt(value?.size_xxl) + parseInt(value?.size_xxxl) + parseInt(value?.other) + parseInt(value?.size_27) + parseInt(value?.size_28) + parseInt(value?.size_29) + parseInt(value?.size_30) + parseInt(value?.size_31) + parseInt(value?.size_32) + parseInt(value?.size_33) + parseInt(value?.size_34) + parseInt(value?.size_35) + parseInt(value?.size_36) + parseInt(value?.size_37) + parseInt(value?.size_38) + parseInt(value?.size_39) + parseInt(value?.size_40) + parseInt(value?.size_41) + parseInt(value?.size_42));
                        totalPrice += parseInt(value?.total);
                    })
                    // console.log(totalStuffUpmore)
                }

                if (entMutif.length > 0) {
                    // console.log("Looping Mutif");
                    entMutif.map((value) => {
                        totalStuffMutif += (parseInt(value?.size_2) + parseInt(value?.size_4) + parseInt(value?.size_6) + parseInt(value?.size_8) + parseInt(value?.size_10) + parseInt(value?.size_12) + parseInt(value?.size_s) + parseInt(value?.size_m) + parseInt(value?.size_l) + parseInt(value?.size_xl) + parseInt(value?.size_xxl) + parseInt(value?.size_xxxl) + parseInt(value?.other) + parseInt(value?.size_27) + parseInt(value?.size_28) + parseInt(value?.size_29) + parseInt(value?.size_30) + parseInt(value?.size_31) + parseInt(value?.size_32) + parseInt(value?.size_33) + parseInt(value?.size_34) + parseInt(value?.size_35) + parseInt(value?.size_36) + parseInt(value?.size_37) + parseInt(value?.size_38) + parseInt(value?.size_39) + parseInt(value?.size_40) + parseInt(value?.size_41) + parseInt(value?.size_42));
                        totalPrice += parseInt(value?.total);
                    })
                    // console.log(totalStuffMutif)
                };

                setTotalDamoza(totalStuffDamoza);
                setTotalMutif(totalStuffMutif);
                setTotalUpmore(totalStuffUpmore);
                setTotalPrice(totalPrice);
            } else {
                setTotalDamoza(0);
                setTotalMutif(0);
                setTotalUpmore(0);
                setTotalPrice(0);
            }
            setIsLoading(false);

        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi')
        }
    }

    function searchItem(input) {
        try {
            const _data = dataConst;
            if (input) {
                let inputUpCase = input.toUpperCase();
                let result = _data.filter(e => {
                    const articleName = e?.article_name || e?.clothes?.article_name
                    return articleName.toUpperCase().includes(inputUpCase)
                });
                // console.log(result);
                setData(result)
            } else {
                setData(_data);
            }
        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi')
        }
    }

    function searchByDate(date) {
        try {
            function format(input) {
                return moment(input).format("MM-DD-YYYY");
            }
            const _data = dataConst;
            let result = _data.filter(e => format(e?.created_at) == format(date));
            setData(result);
        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi')
        }
    }

    function alertConfirmDelete(id, nameStore) {
        try {
            confirmAlert({
                customUI: ({ onClose, }) => {
                    return <Alert
                        titleAlert={"Peringatan"}
                        isAlert
                        alertReguler={`Apakah anda ingin menghapus produk`}
                        varAlert={nameStore}
                        txtNo={"tidak"}
                        txtYes={"ya"}
                        pressNo={() => {
                            onClose()
                            setIsLoading(false)
                        }}
                        pressYes={() => {
                            deleteStuff(id);
                            onClose();
                        }}
                    />
                }
            })
        } catch (e) {
            console.error(e);
            toast.error('Ada Error! Silahkan coba lagi')
        }
    }

    return (
        <div className="w-screen flex flex-col items-center justify-center font-sans">
            <div className='w-full lg:w-1/3 flex flex-col items-center justify-center'>
                <div className="h-11 w-full">
                    <Navbar isDashboard={false} isCart nama_db={isHistory ? "Riwayat" : "Keranjang"} pressBack={() => navigate(-1, { replace: true })} pressHome={() => navigate('/', { replace: true })} />
                </div>
                <ToastContainer />
                {isLoading ? <SplashScreen /> : (
                    <>
                        <div className="mt-3 w-full">
                            {(isFinalData || isHaveToSend ) || isHistory ? null : (
                                <AlertBox title={"Peringatan!!"} content1={"Barang yang ada di keranjang belum final."} content2={"Silahkan untuk melakukan pengeditan barang bila diperlukan!"} />
                            )}
                            <SummaryBox isHaveToSend={isHaveToSend} isFinalData={isFinalData} qtyDamoza={totalDamoza} qtyMutif={totalMutif} qtyUpmore={totalUpmore} price={totalPrice} pressSend={() => sendData()} />
                        </div>
                        <div className="w-full flex justify-center mt-4">
                            <Search onChange={e => searchItem(e.target.value)} placeholder={"Cari Barang..."} />
                        </div>

                        {isHistory ? (
                            <div className='mt-4 p-2 border border-solid border-slate-400 rounded h-10 flex justify-center'>
                                <button className='mr-3 pr-2 h-full border-r active:opacity-50' onClick={() => setData(dataConst)}>
                                    <IconRefresh size={20} />
                                </button>
                                <input type="date" id="birthday" name="birthday" onChange={e => searchByDate(e.target.value)} />
                            </div>
                        ) : null}
                        <div className='w-full block'>
                            {data.length > 0 ? (
                                <div className="w-full box-border flex items-center flex-col">
                                    {data.map((value, key) => {
                                        console.log(value);
                                        try {
                                            const category = value?.type_id || value?.clothes?.type_id;
                                            const articleName = value?.article_name || value?.clothes?.article_name;
                                            const dataInput = category == 2 ? [
                                                { label: "S", value: value?.size_s }, { label: "M", value: value?.size_m }, { label: "L", value: value?.size_l }, { label: "XL", value: value?.size_xl }, { label: "2XL", value: value?.size_xxl }, { label: "3XL", value: value?.size_xxxl },
                                            ] : category == 5 ? [
                                                { label: "lainnya", value: value?.other }
                                            ] : category == 4 ? [
                                                { label: "27", value: value?.size_27 }, { label: "28", value: value?.size_28 }, { label: "29", value: value?.size_29 }, { label: "30", value: value?.size_30 }, { label: "31", value: value?.size_31 }, { label: "32", value: value?.size_32 }, { label: "33", value: value?.size_33 }, { label: "34", value: value?.size_34 }, { label: "35", value: value?.size_35 }, { label: "36", value: value?.size_36 }, { label: "37", value: value?.size_37 }, { label: "38", value: value?.size_38 }, { label: "39", value: value?.size_39 }, { label: "40", value: value?.size_40 }, { label: "41", value: value?.size_41 }, { label: "42", value: value?.size_42 },
                                            ] : [
                                                { label: "2", value: value?.size_2 }, { label: "4", value: value?.size_4 }, { label: "6", value: value?.size_6 }, { label: "8", value: value?.size_8 }, { label: "10", value: value?.size_10 }, { label: "12", value: value?.size_12 },
                                            ];

                                            return <Item
                                                isFinalData={isHistory || isHaveToSend}
                                                key={key}
                                                brand={articleName}
                                                color={value?.info == "-" ? null : value?.info}
                                                describe={value?.veil == 1 ? "Pakai Hijab" : null}
                                                quest={dataInput}
                                                a1={category == 2 ? value?.size_2 : value?.size_s}
                                                a2={category == 2 ? value?.size_4 : value?.size_m}
                                                a3={category == 2 ? value?.size_6 : value?.size_l}
                                                a4={category == 2 ? value?.size_8 : value?.size_xl}
                                                a5={category == 2 ? value?.size_10 : value?.size_xxl}
                                                a6={category == 2 ? value?.size_12 : value?.size_xxxl}
                                                pressDelete={(e) => { e.preventDefault(); alertConfirmDelete(value?.id, articleName) }}
                                                pressEdit={() => navigate(`/katalog/produk/update/${value?.id}`)}
                                                category={category}
                                                price={value?.total}
                                            />
                                        } catch (e) {
                                            console.error(e);
                                            // toast.error(e.message, {autoClose: 900, onClose: () => navigate(-1)})
                                        }
                                    })}
                                </div>
                            ) : <NoStuffCart />}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Cart;

const SummaryBox = ({ qtyDamoza, qtyMutif, qtyUpmore, price, isFinalData, pressSend }) => {
    console.log(isFinalData)
    const ContentSummary = ({ label, value, isRight, isLeft }) => {
        return (
            <div className={`w-full flex flex-col text-lg  items-center box-border border-solid ${isRight ? "border-r" : isLeft ? "border-l" : null}`}>
                <div className="border-b border-solid pb-1 mb-1 font-semibold w-full text-center">{label}</div>
                <div className="text-center">{value}</div>
            </div>
        )
    }

    return (
        <div className="w-full flex items-center justify-center">
            <div className="w-11/12 bg-white shadow-md box-border py-3 px-2 flex flex-col items-center rounded-md">
                <div className="w-full">
                    <div className="text-lg mb-2 text-center flex justify-evenly font-medium text-ellipsis">Total Harga <span className='font-bold'>{currency(price).format({ symbol: "Rp" })}</span></div>
                    <div className="flex py-1 border border-solid justify-evenly">
                        <ContentSummary label={"Damoza"} value={qtyDamoza} isRight />
                        <ContentSummary label={"Mutif"} value={qtyMutif} />
                        <ContentSummary label={"Upmore"} value={qtyUpmore} isLeft />
                    </div>
                </div>
                {isFinalData ? (
                    <div className="flex items-center justify-center mt-3 w-full">
                        <div className="text-center bg-main rounded-md w-full py-2 text-lg font-bold text-white cursor-pointer active:bg-btnDisable noSelect" onClick={pressSend}>
                            kirim
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export const AlertBox = ({ title, content1, content2 }) => {
    return (
        <div className="w-full flex items-center justify-center mb-2">
            <div className="w-11/12 bg-white shadow-md box-border p-2 flex flex-col justify-center rounded">
                <div className="flex items-center mb-2">
                    <IconAlert color='orange' fontWeight={30} size={20} />
                    <span className='ml-1 font-bold text-lg text-orange-400'>{title}</span>
                </div>
                <div className="text-base">
                    <p>{content1}</p>
                    <p>{content2}</p>
                </div>
            </div>
        </div>
    )
}