import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ip } from '../../assets/api';
import { toast, ToastContainer } from 'react-toastify';
import MutifLogo from '../../assets/img/MutifCorp.png';
import { SplashScreen } from '../../components/controlFlow/ControlFlow';
import Select from 'react-select';
import { BeatLoader } from 'react-spinners';


const Register = () => {
    const [nama, setNama] = useState("");
    const [noHP, setNoHP] = useState("");
    const [dbID, setDBID] = useState();
    const [role, setRole] = useState(1);
    const [loc, setLoc] = useState("");
    const [dataDB, setDataDB] = useState([]);
    const [dataRole, setDataRole] = useState([]);
    const [dataAreaLocation, setDataAreaLocation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRole, setIsLoadingRole] = useState(false);
    const [isLoadingDB, setIsLoadingDB] = useState(false);
    const [isLoadingLocation, setIsLoadingLocation] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getNameDB();
        getRole();
        // getAreaLocation();
    }, []);

    function getNameDB() {
        setIsLoadingDB(true);
        var requestOptions = {
            method: 'GET',
        };

        fetch(`${ip}/api/client/get-list-distributor`, requestOptions)
            .then(async response => {
                const res = await response.json();
                // console.log("DB List : ")
                // console.log(res);
                if (response.status == 200) {
                    let dataDBCase = [];
                    res?.data.map((value) => {
                        const roleData = {
                            ...value,
                            label: value?.name
                        }
                        return dataDBCase.push(roleData)
                    });
                    setDataDB(dataDBCase)
                } else {
                    toast(res?.message, {
                        autoClose: 2000
                    })
                }
                setIsLoadingDB(false);
            })
            .catch(error => {
                console.log(error)
                setIsLoadingDB(false);
                toast.error("Ada Error! Silahkan coba lagi", {
                    autoClose: 3000,
                    position: 'top-right',
                })
            })
    }

    function getRole() {
        setIsLoadingRole(true);
        var requestOptions = { method: 'GET', };

        fetch(`${ip}/api/client/get-list-group`, requestOptions)
            .then(async response => {
                const res = await response.json();
                if (response.status == 200) {
                    // console.log(res);
                    let dataRoleCase = [];
                    res?.data.map((value) => {
                        const roleData = {
                            ...value,
                            label: value?.prtnr_code
                        }
                        return dataRoleCase.push(roleData)
                    })
                    setDataRole(dataRoleCase);
                } else {
                    toast(res?.message, {
                        autoClose: 2000
                    })
                }
                setIsLoadingRole(false);
            })
            .catch(error => {
                console.log(error)
                toast.error("Ada Error! Silahkan coba lagi", {
                    autoClose: 3000,
                    position: 'top-right',
                });
                setIsLoadingRole(false);
            })
    }

    function getAreaLocation() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${ip}/api/client/area-client`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status === "success") {
                    let dataAreaLocation = [];
                    result.area.map(value => {
                        const _data = {
                            ...value,
                            label: value.name,
                        }
                        dataAreaLocation.push(_data);
                    })
                    setDataAreaLocation(dataAreaLocation);
                } else {
                    toast.error("Error get data location", {autoClose: 1500, closeOnClick: true});
                }
            })
            .catch(error => {
                console.log('error', error);
                toast("Error get data location", {autoClose: 1500, closeOnClick: true});
            });
    }

    /* function sendData(e: any) {
        setIsLoading(true);
        e.preventDefault();
        let formdata = new FormData();
        formdata.append('name', nama);
        formdata.append('phone', `${noHP}`);
        formdata.append('distributor_id', role != 1 ? dbID : 0);
        formdata.append('partner_group_id', role);

        var requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(`${ip}/api/client/register`, requestOptions)
            .then(response => response.json())
            .then((res) => {
                // console.log(res);
                if (res.status == "success") {
                    toast.success(res.message, {
                        position: "top-right",
                        autoClose: 950,
                        closeOnClick: true,
                        type: 'success',
                        onClose: () => navigate('/login', { replace: true })
                    })
                } else {
                    toast.error(res.message, {
                        position: "top-right",
                        autoClose: 950,
                        closeOnClick: true,
                        type: 'success',
                    })
                }
                setIsLoading(false);

            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
                toast("Ada Error! Silahkan coba lagi", {
                    autoClose: 3000,
                    position: 'top-right',
                })
            })
    } */

    function passToOTP() {
        const toastWarn = (msg) => toast.warn(msg, { autoClose: 1200, closeOnClick: true });
        if (!nama) {
            toastWarn("Nama masih kosong");
        }
        if (!noHP) {
            toastWarn("NO HP masih kosong");
        }
        if (!role) {
            toastWarn("Role masih kosong");
        }
        if (!dbID) {
            toastWarn("Nama DB masih kosong");
        }

        if (nama && noHP && role && dbID) {
            navigate(`/otpConfirm`, { state: { dataUsr: { nama, noHP, role, dbID, loc } } })
        }
    }

    return !role || !dataRole ? <SplashScreen /> : (
        <div className="w-screen h-screen flex flex-col items-center justify-center box-border font-sans">
            <div className='flex flex-col items-center justify-center box-border w-full h-full lg:w-1/3 md:w-1/2'>
                <ToastContainer />
                <img className='w-2/3 mb-3' src={MutifLogo} alt="Gambar Logo" />
                <div className="rounded-md h-auto bg-white shadow-sm shadow-current w-4/5">
                    <div className='rounded-t-md bg-main w-full py-3 text-center font-semibold text-2xl text-white'>
                        Registrasi
                    </div>
                    <div className='flex flex-col items-center justify-center w-full box-border px-4'>
                        <div className='w-full'>
                            <div className='flex flex-col box-border'>
                                <label className='mt-2 font-semibold text-lg'>Nama</label>
                                <input className='p-2 border border-solid rounded-md border-indigo-300' type={"text"} placeholder={"Nama Anda"} required onChange={e => setNama(e.target.value)} />
                            </div>
                            <div className='flex flex-col box-border'>
                                <label className='mt-2 font-semibold text-lg'>No HP</label>
                                <input className='p-2 border border-solid rounded-md border-indigo-300' type={"tel"} placeholder={"Nomor Telepon"} required onChange={e => setNoHP(e.target.value)} />
                            </div>
                            <div className='flex flex-col box-border'>
                                <label className='mt-2 font-semibold text-lg'>Role</label>
                                <Select isLoading={isLoadingRole} options={dataRole} isSearchable={false} onChange={(e) => setRole(e.id)} placeholder={"Pilih Role"} />
                            </div>
                            {/* <div className='flex flex-col box-border'>
                                <label className='mt-2 font-semibold text-lg'>Area Lokasi</label>
                                <Select isLoading={isLoadingLocation} options={dataAreaLocation} isSearchable={false} onChange={(e) => setLoc(e.id)} placeholder={"Pilih Area Lokasi"} />
                            </div> */}
                            <div className='flex flex-col box-border'>
                                <label className='mt-2 font-semibold text-lg'>Nama Distributor</label>
                                <Select isLoading={isLoadingDB} options={dataDB} isSearchable={false} onChange={(e) => setDBID(e.id)} placeholder={"Pilih Nama DB"} />
                            </div>
                        </div>
                        <button className='text-xl bg-main box-border my-3 py-1 px-8 text-white font-bold rounded-md shadow-sm shadow-current active:bg-btnDisable' onClick={passToOTP} disabled={isLoading}>
                            {isLoading ? <BeatLoader color='white' /> : "Daftar"}
                        </button>
                    </div>
                </div>
                <div className="w-full flex flex-col items-center my-5">
                    <div className="text-lg font-normal">Sudah punya akun?</div>
                    <div className="text-lg font-semibold text-blue-700 active:text-blue-300 cursor-pointer" onClick={() => navigate("/login")}>klik login</div>
                </div>
            </div>
        </div>
    )
}

export default Register;
