import { Navbar } from "../../components";
import { IoMdPerson as IconPerson, IoIosHome as IconHome } from 'react-icons/io';
import { IoStorefront as IconStore } from 'react-icons/io5';
import { MdNavigateNext as IconNext } from 'react-icons/md'
// import "./style/profile.scss";
// import DummyAvatar from '../../assets/img/dummy_img/avatar.png';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ip } from "../../assets/api";
import { toast, ToastContainer } from "react-toastify";
// import Modal from 'react-awesome-modal';
import { SplashScreen } from "../../components/controlFlow/ControlFlow";
import { colors } from "../../assets/colors";

const Profile = () => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getDataState();
    }, []);

    // useEffect(() => {
    //     console.log("data")
    //     console.log(data)
    // }, [data])

    function getDataState() {
        setIsLoading(true)
        const phone = localStorage.getItem('phone');
        const requestOptions = {
            method: "GET",
            redirect: 'follow',
        };

        fetch(`${ip}/api/client/login/${phone}`, requestOptions)
            .then(res => res.json())
            .then(response => {
                // console.log("response Home Screen");
                console.log(response)
                if (response.status == "failed") {
                    toast.error(response.message, { autoClose: 1500, onClose: () => navigate(-1) })
                } else {
                    setData(response.user);
                    sessionStorage.setItem("isFinalData", response.final_data.length > 0 ? true : false);
                    setIsLoading(false)
                }
            })
            .catch(err => {
                console.error(err)
                toast.error(err.message, {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    onClose: () => {
                        localStorage.removeItem('phone');
                        navigate('/login', { replace: true });
                    }
                })
                setIsLoading(false);
            })
    }

    function validationRole() {
        try {
            if (data?.partner_group_id != 1) {
                if (data?.mutif_store_master.length > 0) {
                    navigate('/profile/phone', { state: data?.partner_group_id })
                } else {
                    toast.warn('Anda belum melakukan pendaftaran toko!', { autoClose: 1500 })
                }
            } else {
                toast.warn("Untuk mengedit no HP, silahkan hubungi Admin!", { autoClose: 1000 })
            }

        } catch (e) {
            console.error(e)
            toast.error(e.message, { autoClose: 900, onClose: () => navigate(-1) })
        }
    }

    function capitalizeFirstLetter(string) {
        try {
            const lower = string.toLowerCase();
            return lower.charAt(0).toUpperCase() + lower.slice(1);
        } catch (e) {
            toast.error("Ada Error! Silahkan coba lagi")
        }
    }

    return (
        !isLoading ? (
            <div className="w-screen flex flex-col items-center box-border font-sans">
                <div className="flex flex-col items-center box-border w-full lg:w-1/3">
                    <div className="h-11 w-full">
                        <Navbar pressBack={() => navigate(-1, { replace: true })} nama_db="Profile" />
                    </div>
                    <ToastContainer />
                    <div className="w-11/12 flex flex-col justify-center">
                        <div className="mt-3 box-content shadow p-2 rounded-lg flex flex-col items-center">
                            <div className="text-xl font-bold flex items-center my-2">
                                <IconPerson />
                                <span className="ml-2">Data Pribadi</span>
                            </div>
                            <div className="flex flex-col pl-1 py-2 gap-1 w-full justify-evenly">
                                <ItemProfile label={"Nama"} value={data?.name} />
                                {/* <ItemProfile label={"telepone"} value={data?.phone} isEdit nameBtn={"Edit Phone"} /> */}
                                <ItemProfile label={"telepone"} value={data?.phone} isEdit pressEdit={() => navigate('/profile/phone', { state: data?.partner_group_id })} nameBtn={"edit"} />
                                {/* <ItemProfile label={"telepone"} value={data?.phone} /> */}
                                <ItemProfile label={"level"} value={data?.level} />
                                {data?.partner_address ? (
                                    <>
                                        <ItemProfile label={"alamat"} value={data?.partner_address.address} />
                                        <ItemProfile label={"Kec"} value={capitalizeFirstLetter(data?.partner_address.district)} />
                                        <ItemProfile label={"Kota/Kab"} value={capitalizeFirstLetter(data?.partner_address.regency)} />
                                        <ItemProfile label={"Provinsi"} value={capitalizeFirstLetter(data?.partner_address.province)} />
                                        <ItemProfile label={"Kode Pos"} value={data?.partner_address.zip ? data?.partner_address.zip : "~"} />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {/* <div className="edit-wrapper">
                { !data?.partner_address ? (
                    <BtnNavEdite route={1} title={"Alamat Pribadi"} pressNav={() => navigate("/profile/update/user")} />
                ) : <BtnNavEdite route={1} title={"Edit Alamat Pribadi"} pressNav={() => navigate("/profile/update/user", {state: {user: data?.partner_address, isEditStore: false}})} />}
                <BtnNavEdite title={"Toko"} pressNav={() => navigate("/profile/list-toko")} />
            </div> */}
                </div>
            </div>
        ) : <SplashScreen />
    )
}

export default Profile;

const BtnNavEdite = ({ route, title, pressNav }) => {
    return (
        <div className="w-full mt-4 p-4 box-border flex items-center justify-between text-white rounded-md shadow noSelect active:opacity-60" style={{ backgroundColor: route == 1 ? colors.main : "#EAAE13" }} onClick={pressNav} >
            <div className="text-3xl">
                {route == 1 ? <IconHome /> : <IconStore />}
            </div>
            <div className="text-xl font-bold text-center">{title}</div>
            <div className="text-3xl">
                <IconNext />
            </div>
        </div>
    )
}

const ItemProfile = ({ value, label, isEdit, pressEdit, nameBtn }) => {
    return (
        <div className="inline-flex w-full box-border text-base gap-x-2.5 items-center">
            <div className="w-1/4 font-semibold">{label}</div>
            <div className="w-1/2">: {value}</div>
            <div className="w-1/4 noSelect text-center bg-main text-white font-semibold rounded shadow active:bg-btnDisable" style={{ visibility: isEdit ? "visible" : "hidden" }} onClick={pressEdit}>{nameBtn}</div>
        </div>
    )
}
