import { useRef, useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { auth } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, } from 'firebase/auth';
import { SplashScreen } from "../../components/controlFlow/ControlFlow";
import { ip } from "../../assets/api";
import { createBrowserHistory } from 'history';
import { BeatLoader, override } from './Login';

function OTPScreen() {
    const [otpCode, setOtpCode] = useState();
    const [phone, setPhone] = useState();
    const [isCountingTime, setIsCountingTime] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataUser, setDataUser] = useState();

    const countDownRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const history = createBrowserHistory();

    useEffect(() => {
        firstRender();
    }, [])

    function firstRender() {
        try {
            setIsCountingTime(true);
            const data_usr = location.state?.dataUsr;
            const isHaveRecaptchar = window.recaptchaVerifier;
            // console.log("Data User");
            // console.log(data_usr);
            setDataUser(data_usr);

            if (data_usr) {
                const noHP = data_usr?.noHP;
                if (noHP[0] === "0") {
                    console.log("Mengandung angka NOL")
                    const modHP = noHP.slice(1);
                    const result = "+62" + modHP;
                    setPhone(result);
                    onSignInSubmit(result);
                    onSignInSubmit(result);
                } else if (noHP.substring(0, 2) === "62") {
                    ;
                    const result = "+" + noHP;
                    setPhone(result);
                    onSignInSubmit(result);
                } else {
                    setPhone(noHP);
                    onSignInSubmit(noHP);
                    onSignInSubmit(noHP);
                }
            }
        } catch (e) {
            console.log("Error First Render OTP Screen", e);
        }
    }

    function configureCaptcha() {
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                console.log("Configure Recaptcha");
                // console.log(response);
                onSignInSubmit();
            }
        }, auth);
    }

    function onSignInSubmit(noPhone) {
        startCountDown();
        const phoneNumber = noPhone || phone;
        const appVerifier = window.recaptchaVerifier;
        configureCaptcha();

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.log("OTP Sended");
                // console.log(confirmationResult);
                toast("Berhasil kirim OTP", {
                    position: "top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                    type: 'success',
                });
            }).catch((error) => {
                console.log(`Error On Send OTP ${error}`);
            });
    }

    function onSendOTP(e) {
        setIsLoading(true);
        e.preventDefault();
        const code = otpCode;
        window.confirmationResult.confirm(code).then((result) => {
            console.log("Success Send OTP");
            toast("OTP terverifikasi", {
                position: "top-right",
                autoClose: 1500,
                closeOnClick: true,
                type: 'success',
                onClose: () => sendData(),
            });
            const user = result.user;
            console.log(user);
        }).catch((error) => {
            console.log(`Error OTP ${error}`);
            setIsLoading(false);
        });
    }

    function sendData() {
        console.log(`Data User : 
            name : ${dataUser.nama},
            phone : ${dataUser.noHP},
            DBID : ${dataUser.dbID},
            PartnerID : ${dataUser.role}
            LocID : ${dataUser.loc}
        `);
        try {
            let formdata = new FormData();
            formdata.append('name', dataUser.nama);
            formdata.append('phone', `${dataUser.noHP}`);
            formdata.append('distributor_id', dataUser.role != 1 ? dataUser.dbID : 0);
            formdata.append('partner_group_id', dataUser.role);
            // formdata.append('area_id', dataUser.loc);

            var requestOptions = {
                method: 'POST',
                body: formdata,
            };

            fetch(`${ip}/api/client/register`, requestOptions)
                .then(response => response.json())
                .then((res) => {
                    if (res.status == "success") {

                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 950,
                            closeOnClick: true,
                            onClose: () => { setIsLoading(false); navigate('/login', { replace: true }) }
                        })
                    } else {
                        console.log("Error Registrasi")
                        console.log(res);
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 950,
                            closeOnClick: true,
                            onClose: () => { setIsLoading(false); history.go(-1) },
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                    toast("Ada Error! Silahkan coba lagi", {
                        autoClose: 3000,
                        position: 'top-right',
                    });

                })
        } catch (e) {
            console.log(e);
            toast.error("Ada error saat kirim data!", { autoClose: 1500, closeOnClick: true });
        }
    }

    const startCountDown = () => {
        setIsCountingTime(true);
        countDownRef.current?.start();
    }

    const completeCountDown = () => {
        setIsCountingTime(false);
    }

    return isLoading ? <SplashScreen /> : (
        <div className="flex flex-col items-center w-screen px-4 my-20 font-sans">
            <div className="flex flex-col items-center w-full h-full lg:w-1/3 md:w-1/2">
                <ToastContainer />
                <div className="flex flex-col w-auto items-center mb-5">
                    <img src={require('../../assets/img/otp-image.png')} className="w-2/3" />
                    <h1 className="text-2xl font-semibold mt-5">Masukan Kode OTP</h1>
                </div>
                <div className="flex flex-col mb-8 text-3xl items-center">
                    <OtpInput
                        value={otpCode}
                        onChange={value => setOtpCode(value)}
                        numInputs={6}
                        separator={<span>-</span>}
                        inputStyle={{ borderRadius: 5, borderWidth: 1 }}
                        isInputNum
                    />
                    {!otpCode ? null : isLoading ? (
                        <div className="inline-block text-2xl mt-7 px-10 py-1 rounded-md text-white font-semibold bg-main">
                            <BeatLoader loading css={override} color={'white'} />
                        </div>
                    ) : (
                        <button className="inline-block text-2xl mt-7 px-10 py-1 rounded-md text-white font-semibold bg-main" onClick={onSendOTP}>
                            kirim
                        </button>
                    )}
                </div>
                <div className="text-center leading-relaxed">
                    <Countdown
                        ref={countDownRef}
                        onComplete={completeCountDown}
                        date={Date.now() + 180000} />
                    {isCountingTime ? null : (
                        <button onClick={() => {
                            startCountDown();
                            window.location.reload();
                        }} className="block">
                            Kirim Ulang
                        </button>
                    )}
                </div>
                <div id="sign-in-button"></div>
            </div>
        </div>
    )
}

export default OTPScreen;