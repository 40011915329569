import { ToastContainer } from 'react-toastify';
import ImgClose from '../../assets/img/app-close.png';
import "./exitApp.scss";

const ExitApp = ({onPress}) => {
    return(
        <div className="exit-container">
            <ToastContainer />
            <div className="img-exit">
                <img src={ImgClose} alt="Gambar Website Ditutup" />
            </div>
            <div className="desc-exit">
                <p>Sesi telah berakhir....</p>
            </div>
            <div className="button" onClick={onPress}>
                <button className='endsessionbtn'>Log Out</button>
            </div>
        </div>
    )
}

export default ExitApp