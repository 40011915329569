import { MouseEventHandler, useEffect, useContext } from 'react';
import './item.scss';
import { ModButton } from '../button/Button';
import { colors } from '../../assets/colors';
import currency from 'currency.js';

type itemProps = {
    img?: string,
    brand?: String,
    pressDelete?: () => void,
    pressEdit?: () => void,
    category?: string
    a1?: string,
    a2?: string,
    a3?: string,
    a4?: string,
    a5?: string,
    a6?: string,
    price?: number | any,
    isFinalData?: Boolean,
    color?: string,
    describe?: string,
    quest?:string | any,
    value?:string | number,
}

type itemForm = {
    img?: string,
    brand?: String,
    code?: String,
    idPage?: number,
    onCLick?: MouseEventHandler,
    entity?: string,
    isAllProduct?: boolean,
    isOnCart?: boolean,
}

export const Item: React.FC<itemProps> = ({
    img, 
    brand, 
    pressDelete,
    pressEdit,
    category,
    price,
    isFinalData,
    color,
    describe,
    quest,
    a1 = 0, a2 = 0, a3 = 0, a4 = 0, a5 = 0, a6 = 0
}) => {

    return(
        <div className="items-container">
            <div className="content">
                <div className="info-cart">
                   {/* <div className="img-product">
                        <img src={Produk1} alt="Gambar Produk" />
                   </div> */}
                   <div className="info-product">
                        <div className="title-product">
                            <div className="main-title">{brand} {color}</div>
                            <div className="describe">{describe}</div>
                        </div>
                        <div className="size">
                            {Array.isArray(quest) ? (
                                quest.map((value: any, key: any) => {
                                    return value.value != 0 ? <QuestItem key={key} quest={`${value.label}`} value={value.value} /> : null 
                                    // if(key <= 3) {
                                    // }
                               })
                            ) : null}
                        </div>
                        <div className="price">Harga : <span>{currency(price).format({symbol: 'Rp'})}</span></div>
                    </div>
                </div>
                {
                    isFinalData ? null : (
                        <div className="button">
                            <ModButton isDelete onClick={pressDelete} />          
                            <ModButton onClick={pressEdit} />          
                        </div>
                    ) 
                }
            </div>
        </div>
    )
}

const QuestItem :React.FC<itemProps> = ({quest, value}) => {
    return(
        <div className="size-wrapper">
            <div className="label">{quest}</div>
            <div className="data">{value}</div>
        </div>
    )
}

export const ItemForm: React.FC<itemForm> = ({img, brand, code, idPage, onCLick, entity, isAllProduct, isOnCart}) => {
    return(
        <div className="item-form" onClick={onCLick}>
            <div className="wrapper-content">
                {/* <div className="img">
                     <img src={img ? img : 'https://s1.bukalapak.com/img/6610097811/large/18013693_1488345331216451_6182312729160712192_n.jpg'} />
                </div> */}
                <div className="content-form" style={{color: entity == "MUTIF" || isAllProduct ? colors.main : 'black'}}>
                    <h3>{brand}</h3>
                    <p>{code}</p>
                </div>
                <div className="id-item">
                    <p style={{backgroundColor: isOnCart ? colors.agree : colors.main}}>{idPage}</p>
                </div>
            </div>
        </div>
    )
}
